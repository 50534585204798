.device-token-content > h1 {
    font-size: 1.4em;
    font-weight : 300;
    text-align: center;
    margin-bottom: 1em;
    cursor: default;
    padding-left: 8%;
    padding-right: 8%;
}

.dt-form > .form-group {
    margin-bottom: 1.5em;
}

.device-token-content .dt-submit-btn{
    font-size: 1.5em;
}