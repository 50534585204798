/** Page Container **/
  .dashboard-container * .dashboard-grid {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 260px 1fr;
    grid-template-rows: auto 1fr;
    margin: 0 auto;
    border: none;
  }
  
  .dashboard-container * .dashboard-title {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .dashboard-container * .dashboard-sidebar-select-account-container {
    display: grid;
    grid-template-rows: auto auto;
  }

  /** Sidebar **/
  .dashboard-container * .dashboard-sidebar-select-account select.form-control {
    color: var(--primary-color);
    background: var(--select-down-svg) 100% 70% no-repeat;
    background-size: auto 21.75%;
    padding-left: 0.2em;
    padding-right: 0.2em;
    border-bottom-width: 1px;
  }
  .dashboard-container * .dashboard-sidebar-select-timewindow select {
    color: var(--primary-color)
  }

  .dashboard-container * .dashboard-sidebar-select-account-container {
    min-height: 6.2em;
    margin-bottom: 1.5em;
  }

  
  .dashboard-container .dashboard-sidebar-nav-accounts-loading {
    font-weight: 300;
    padding-top: 0.5em;
  }
  .dashboard-container .dashboard-sidebar-nav-accounts-loading svg{
    color: var(--primary-color);
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container {
    display: grid;
    grid-template-columns: 1fr 1em 1fr;
    margin-bottom: 0.75em;
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    display: none;
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    padding: 0.0825em 0 0.165em;
  }
  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container .react-datepicker__month-select, .dashboard-container .dashboard-sidebar .dashboard-date-picker-container .react-datepicker__year-select {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--text-underline);
    background: var(--select-down-svg) 100% 60% no-repeat;
    background-size: auto 30%;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 1em;
  }

  .dashboard-container .dashboard-sidebar .react-datepicker__input-container {
    margin: 0 auto;
    width: 100%;
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-input {
    display: grid;
    grid-template-columns: auto auto;
    padding-left: 0.2em;
    padding-bottom: 0.1em;
    border-bottom: 1px solid var(--text-underline);
    color: var(--primary-color);
    font-size: 0.9em;
    cursor: pointer;
  }
  .dashboard-container .dashboard-sidebar .dashboard-date-picker-input.dashboard-date-picker.disabled{
    color: #333;
    border-bottom-color: #eaeaea;
    cursor: default;
    opacity: 0.5;
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-input .dashboard-date-picker-input-arrow{
    text-align: right;
    font-size: 1.1em;
  }


  .dashboard-sidebar-select-timespan-container{
    display: grid;
    grid-template-columns: repeat(4, auto);
    color: var(--text-secondary);
    font-size: 0.8em;
    margin-bottom: 2em;
    text-align: center;
  }
  .dashboard-sidebar-select-timespan-container.fr{
    display: grid;
    grid-template-columns: repeat(4, auto);
    color: var(--text-secondary);
    font-size: 0.8em;
    margin-bottom: 2em;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item {
    cursor: default;
    padding: 0 0.5em;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item:first-child {
    padding-left: 0;
    text-align: left;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item:nth-child(4) {
    text-align: center;
  }
  .dashboard-sidebar-select-timespan-container.fr .dashboard-sidebar-select-timespan-item:nth-child(4) {
    text-align: right;
    padding-right: 0;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item:last-child {
    text-align: right;
    padding-right: 0;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item span {
    cursor: pointer;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item.disabled span {
    color: #333;
    cursor: default;
    opacity: 0.5;
  }

  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item:last-child span {
    cursor: default;
  }

  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item.active span{
    color: var(--primary-color);
    border-bottom: 1px solid var(--text-underline);
    cursor: default;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item.active.disabled span{
    color: #333;
    border-bottom-color: #eaeaea;
    cursor: default;
    opacity: 0.5;
  }
  /* Sidebar Stats */
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item {
    margin-bottom: 2em;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-header {
    color: var(--primary-color);
    font-size: 1.125em;
    font-weight: 500;
    cursor: default;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center; 
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-header .dashboard-sidebar-stats-item-header-help {
    font-size: 0.7em;
    margin-top: 0.15em;
    color: var(--text-secondary);
    text-align: right;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-header .dashboard-sidebar-stats-item-header-help svg:hover{
    cursor: pointer;
    color: var(--primary-color);
  }
  .dashboard-stats-help-box {
    font-size: 0.8em;
  }
  .dashboard-stats-help-box .popover-content {
    padding: 0.5em 0.75em;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-value {
    font-size: 1.5em;
    padding: 0.25em 0.5em;
    background-color: #FFF;
    -webkit-box-shadow: 0px 1px 0px 0px #eaeaea;
    -moz-box-shadow: 0px 1px 0px 0px #eaeaea;
    box-shadow: 0px 1px 0px 0px #eaeaea;
    cursor: default;
  }
  .dashboard-sidebar-stats .small-symbol {
    font-size: 0.75em;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-fee-values {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-fee-value {
    font-size: 1.5em;
    padding: 0.25em 0.5em;
    background-color: #FFF;
    -webkit-box-shadow: 0px 1px 0px 0px #eaeaea;
    -moz-box-shadow: 0px 1px 0px 0px #eaeaea;
    box-shadow: 0px 1px 0px 0px #eaeaea;
  }

  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-fee-value:first-of-type {
    margin-right: 2em;
  }

  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-subtext {
    font-size: 0.75em;
    padding-top: 0.25em;
    color: var(--text-secondary);
    cursor: default;
  }
  
  /** Dashboard Content **/
  .dashboard-container .dashboard-content-container {
    padding-left: 5.65em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container{
    margin-bottom: 3em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .dashboard-content-gow-header {
    color: var(--primary-color);
    font-size: 1.125em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .growthofwealthchart-chart-container.gow-chart {
    background-color: #FFF;
    padding-left: 2em;
    padding-right: 4em;
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100%;
    height: 400px;
    -webkit-box-shadow: 0px 1px 1px 0px #eaeaea;
    -moz-box-shadow: 0px 1px 1px 0px #eaeaea;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    position: relative;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .growthofwealthchart-chart-container.gow-chart .gow-tooltip {
    position: absolute;
    background-color: #fff;
    border: 1px solid var(--text-secondary);
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-header{
    padding: 0.2em;
    text-align: center;
    font-size: 0.7em;
    background-color:#f9f8f7;
    color: var(--text-secondary)
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content {
    font-size: 0.8em;
    padding: 0.25em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-value-item {
    display: grid;
    grid-template-columns: 1em auto auto;
    align-items: baseline;
    font-size: 0.7em; 
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-value-item .legend-rect {
    height: 0.6em;
    margin: 0.1em 0.3em 0.3em 0.1em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-value-item > span {
    display: inline-block;
    font-size: 1.3em;
    text-align: right;
    padding-left: 1em
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-managed .legend-rect{
    background-color: #c5d368;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-total {
    border-top: 1px solid #dfdfdf;
    margin-top: 0.2em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-total .legend-rect{
    background-color: #aaa;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-nonmanaged .legend-rect{
    background-color: #aaa;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-contributions {
    font-size: 0.7em;
    margin-top: 0.8em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-contributions-title .legend-rect {
    display: inline-block;
    width: 0.6em;
    height: 0.25em;
    margin: 0.15em 0.2em 0.2em 0.2em;
    background-color: var(--primary-color)
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-contributions-value {
    text-align: right;
    margin-left: 1em;
    font-size: 1.3em;
    margin-bottom: 0.25em;
  }
  .dashboard-content-pfsummary-content-container {
    display: grid;
    grid-template-columns: 250px 1fr;
  }
  .dashboard-content-pfsummary-chart .component-loading-placeholder .component-loading-text{
    font-size: 1em;
  }
  .dashboard-content-pfsummary-container .dashboard-content-pfsummary-header {
    color: var(--primary-color);
    font-size: 1.125em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .dashboard-content-pfsummary-container .dashboard-content-pfsummary-header span {
    font-size: 0.8em;
    color: var(--text-secondary);
  }
  .dashboard-content-pfsummary-content-container {
    background-color: #FFF;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100%;
    -webkit-box-shadow: 0px 1px 1px 0px #eaeaea;
    -moz-box-shadow: 0px 1px 1px 0px #eaeaea;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .dashboard-content-pfsummary-chart-header { 
    text-align: center;
    color: var(--text-secondary);
    width: 100%;
    padding-top: 0.25em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .dashboard-content-pfsummary-chart-container {
    width: 250px;
    height: 250px;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .recharts-wrapper{
    transform: translatey(-20px);
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip {
    transform: translate(-50%, -20px);
    white-space: nowrap;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .small-symbol {
    font-size: 0.8em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .label-pct .small-symbol{
    font-size: 0.6em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .label-pct{
    font-size: 1.25em;
    margin-bottom: 0.05em;
    text-align: center;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .label-pct .label-pct-sm {
    font-size: 0.8em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .label-cat{
    font-size: 0.85em;
    margin-bottom: 0.15em;
    text-transform: capitalize;
    text-align: center;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table {
    padding-left: 3em;
    white-space: nowrap;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .dashboard-content-pfsummary-table-header {
    display: grid;
    grid-template-columns: 1.75em 1.2fr 0.8fr 0.8fr 0.01fr;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .dashboard-content-pfsummary-table-header-item {
    font-size: 0.9em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: var(--text-secondary);
    text-align: center;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .dashboard-content-pfsummary-table-header-item.title-item {
    text-align: left;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .dashboard-content-pfsummary-table-row {
    display: grid;
    grid-template-columns: 1.75em 1.2fr 0.8fr 0.8fr 0.01fr;
    align-items: center;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .small-symbol{
    font-size: 0.8em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-row .dashboard-content-pfsummary-table-legend-box {
    height: 1.125em;
    width: 1.125em;
    border-radius: 50%;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-row .dashboard-content-pfsummary-table-row-item {
    text-align: center;
    padding: 0.5em 0;
    border-top: 1px solid #dfdfdf;
    font-weight: 300;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-row .dashboard-content-pfsummary-table-row-item.title-item {
    text-transform: capitalize;
    text-align: left;
    font-weight: 300;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-row .dashboard-content-pfsummary-table-row-item.box-item {
    border-top: none;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-total {
    display: grid;
    grid-template-columns: 1.75em 1.2fr 0.8fr 0.8fr 0.01fr;
    align-items: center;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-total .dashboard-content-pfsummary-table-total-item {
    text-align: center;
    padding: 0.5em 0;
    border-top: 1px solid var(--text-secondary);
    color: var(--primary-color);
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-total .dashboard-content-pfsummary-table-total-item.title-item {
    text-align: left;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-total .dashboard-content-pfsummary-table-total-item.box-item {
    border-top: none;
  }

 @media ( max-width: 1280px ) { 
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table {
    font-size: 0.8em;
  }
 }
 @media ( max-width: 1024px ) {  
  .dashboard-container .dashboard-content-container {
    padding-left: 2em;
  }
  .dashboard-content-pfsummary-content-container{
    grid-template-columns: 200px 1fr;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .dashboard-content-pfsummary-chart-container {
    width: 200px;
    height: 250px;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .recharts-wrapper{
    transform: translatey(-0px);
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip {
    transform: translate(-50%, 0px);
  }
}
@media ( max-width: 991px ) { 
  .dashboard-container * .dashboard-grid {
    display: grid;
    grid-template-columns: 260px 1fr;
    grid-template-rows: auto 1fr;
    border: none;
  }
  .dashboard-container * .dashboard-sidebar {
    margin-left: 0em;
    position: relative;
  }
  .dashboard-summary-container {
    margin-left: 0em;
  }
  .dashboard-container .dashboard-sidebar{
    max-width: 260px;
  }
  .dashboard-container * .dashboard-sidebar-select-account-container {
    display: grid;
    grid-template-rows: auto auto;
    max-width: 260px;
  }
}
@media ( max-width: 768px ) {
  .dashboard-container {
    font-size: 0.8em;
  }
  .dashboard-container * .dashboard-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    border: none;
    min-width: 300px;
  }
    
  .dashboard-container .dashboard-grid {
    padding-left: 0;
    padding-right: 0;
  }
  .dashboard-container .dashboard-sidebar {
     width: 100%;
     max-width: 100%;
  }

  .dashboard-container .dashboard-sidebar .dashboard-sidebar-select-account-container {
    grid-template-columns: 260px auto;
    max-width: 100%;
    margin-bottom: 1em;
  }
  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container {
    font-size: 1.2em;
    grid-column-start: 1;
  }
  .dashboard-container .dashboard-sidebar .dashboard-sidebar-select-timespan-container {
    grid-column-start: 2;
    padding-left: 2.5em;
    padding-top: 0.4em;
    font-size: 1em;
    max-width: 325px;
  }
  .dashboard-container .dashboard-sidebar .dashboard-sidebar-stats{
    display: grid;
    grid-template-columns: calc(260px + 1em) 1fr 0.5fr;
  }
  .dashboard-container .dashboard-sidebar .dashboard-sidebar-stats-item {
    padding-right: 1em;
  }
  .dashboard-container .dashboard-sidebar .dashboard-sidebar-stats-item:last-child {
    padding-right: 0em;
  }

  .dashboard-container .dashboard-content-container {
    padding-left: 2%;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .growthofwealthchart-chart-container.gow-chart {
    height: 300px;
  }
  .dashboard-container .dashboard-content-pfsummary-content-container{
    grid-template-columns: 1fr;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .dashboard-content-pfsummary-chart-container {
    margin: auto;
  }
}
