@media all and (min-width: 480px) {
  .sq {
    padding: 60px 0;
  }

  .sq form {
    margin: 0 auto;
    max-width: 450px;
  }
}

.sq form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.sq-logo-container {
  text-align: center;
  margin-bottom: 1em;
}

img.logo-icon-image {
    height: 2.5em;
    margin-right: 0.25em;
    vertical-align: middle;
}

img.logo-text-image {
    vertical-align: middle;
    margin-top: 0.2em;
}

.sq-container {
  margin: 0 auto;
}
.sq-container > h1 {
  font-size: 1.5em;
  font-weight : 300;
  text-align: center;
  margin-bottom: 1em;
  cursor: default;
}
.sq-form > .form-group {
  margin-bottom: 1.5em;
}

.half-form-input {
  max-width: 200px;
  display: inline-block;
}
.half-form-input:nth-of-type(1) {
  margin-right:50px;
}
.sq-pat-text.form-group{
  font-size: 0.8em;
  text-align: center;
  opacity: 0.6;
  margin-bottom: 0.5em;
  cursor: default;
}
.sq-pat-text.form-group * a {
  cursor: pointer;
}
.sq-submit-btn{
  font-size: 1.5em;
}