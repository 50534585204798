/** Page Container **/
  .portfolios-container * .portfolios-grid {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 260px 1fr;
    margin: 0 auto;
    border: none;
  }

  .portfolios-container * .portfolios-title {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .portfolios-container * .portfolios-sidebar-select-account-container {
    display: grid;
    grid-template-rows: auto auto;
  }

  @media ( max-width: 991px ) {
    .portfolios-container * .portfolios-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      border: none;
    }
    .portfolios-container * .portfolios-sidebar {
      margin-left: 0em;
      position: relative;
    }
    .portfolios-summary-container {
      margin-left: 0em;
    }

  }
  
/** Sidebar **/
  .portfolios-container * .portfolios-sidebar-select-account select.form-control {
    color: var(--primary-color);
    background: var(--select-down-svg) 100% 70% no-repeat;
    background-size: auto 21.75%;
    padding-left: 0.2em;
    padding-right: 0.2em;
    border-bottom-width: 1px;
  }

  .portfolios-container * .portfolios-sidebar-select-account-container {
    min-height: 6.2em;
    margin-bottom: 1.5em;
    max-width: 260px;
  }

  .portfolios-container .portfolios-sidebar-nav-accounts-loading {
    font-weight: 300;
    padding-top: 0.5em;
  }
  .portfolios-container .portfolios-sidebar-nav-accounts-loading svg{
    color: var(--primary-color);
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 0.75em;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    display: none;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    padding: 0.0825em 0 0.165em;
  }
  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container .react-datepicker__month-select, .portfolios-container .portfolios-sidebar .portfolios-date-picker-container .react-datepicker__year-select {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--text-underline);
    background: var(--select-down-svg) 100% 60% no-repeat;
    background-size: auto 30%;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 1em;
  }

  .portfolios-container .portfolios-sidebar .react-datepicker__input-container {
    margin: 0 auto;
    width: 100%;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-input {
    display: grid;
    grid-template-columns: auto auto;
    padding-left: 0.2em;
    padding-bottom: 0.1em;
    border-bottom: 1px solid var(--text-underline);
    color: var(--primary-color);
    font-size: 0.9em;
    cursor: pointer;
  }
  .portfolios-container .portfolios-sidebar .portfolios-date-picker-input.portfolios-date-picker.disabled{
    color: #333;
    border-bottom-color: #eaeaea;
    cursor: default;
    opacity: 0.5;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-input .portfolios-date-picker-input-arrow{
    text-align: right;
    font-size: 1.1em;
  }
  @media ( max-width: 991px ) {
    .portfolios-container * .portfolios-sidebar-select-account-container {
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  /** Portfolio Content **/
  .portfolios-content {
    padding: 0 0em 0 5.75em;
  }

  .portfolios-content .portfolios-content-account-header {
    color: var(--text-secondary);
    font-size: 1em;
    font-weight: 500;
    display: inline-block;
    padding-left: 0.5em;
  }

  .portfolios-content .portfolios-content-summary-container .portoflios-content-summary-header{
    color: var(--primary-color);
    font-size: 1.25em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .portfolios-content .portfolios-content-summary-container .component-loading-placeholder {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .portfolios-content .portfolios-content-summary-container .component-loading-placeholder .component-loading-text {
    font-size: 1em;
  }


  
  .portfolios-content .portfolios-content-summary-container .dashboard-content-pfsummary-content-container {
    background-color: #FFF;
    padding: 1em 3em 2em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 4em;
  }

  .portfolios-content .portfolios-content-summary-container .portfolios-content-summary-content .recharts-wrapper:first-child {
    margin-bottom: -120px;
    margin-right: 190px;
  }
  .portfolios-content .portfolios-content-summary-container .portfolios-content-summary-content .recharts-wrapper:first-child .recharts-pie-sector path{
    stroke-width: 1px;
    stroke: #FFF;
  }
  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .recharts-bar-rectangles.recharts-layer {
    stroke-width: 1px;
    stroke: #FFF;
    shape-rendering:crispEdges;
  }

  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-top {
    text-anchor: middle;
    transform: translate(50%, 0.25em);
  }

  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-top tspan{
    shape-rendering:crispEdges;
    font-weight: 300;
  }

  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-top tspan.small-symbol {
    font-size: 0.8em;
  }

  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-bottom {
    text-anchor: middle;
    transform: translate(50%, 97%);
  }
  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-bottom tspan{
    font-size: 1.125em;
    font-weight: 300;
  }
  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-bottom tspan.small-symbol{
    font-size: 0.75em;
  }
  /* .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip{
    width: 300px;
    transform: translate(-50%, -2em);
    text-align: center;
  }
  .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip .small-symbol {
    font-size: 0.8em;
  }
  .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip .label-pct .small-symbol{
    font-size: 0.6em;
  }
  .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip .label-pct{
    font-size: 1.5em;
    margin-bottom: 0.15em;
  }
  .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip .label-cat{
    font-size: 0.85em;
    margin-bottom: 0.15em;
    text-transform: capitalize;
  } */

  .portfolios-content .portfolios-content-summary-container .portfolios-content-summary-content .recharts-wrapper .recharts-legend-item-text {
    vertical-align: middle;
    text-transform: capitalize;
  }



  .portfolios-content .portfolios-content-details-container .portoflios-content-details-header{
    color: var(--primary-color);
    font-size: 1.25em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-content {
    background-color: #FFF;
    padding: 1em 3em 3em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 4em;
    cursor: default;
  }

  .portfolios-content .portfolios-content-details-container .component-loading-placeholder .component-loading-text {
    font-size: 1em;
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-portfolios-container {
    display: grid;
    grid-template-columns: 0.4fr 0.5fr 0.5fr 0.8fr 0.5fr 0.8fr;
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-portfolios-header-item {
    padding: 0.5em 0.25em;
    color: var(--text-secondary);
    border-bottom: 1px solid #aaa;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-header-item.pct-item {
    text-align: center;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item {
    padding: 0.75em 0.25em;

    border-bottom: 1px solid #dfdfdf;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item .small-symbol {
    font-size: 0.8em;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item.account-item {
    font-weight: 300;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item.type-item {
    font-weight: 300;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item.curr-item {
    font-weight: 300;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item.pct-item {
    font-weight: 300;
    text-align: center;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(1),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(2),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(3),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(4),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(5),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(6){
    border-bottom: none;
  }

  .portfolios-content .portfolios-content-holdings-container {
    cursor: default;
  }

  .portfolios-content .portfolios-content-holdings-container .component-loading-placeholder .component-loading-text {
    font-size: 1em;
  }
  .portfolios-content .portfolios-content-holdings-container .portoflios-content-holdings-header{
    color: var(--primary-color);
    font-size: 1.25em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-content {
    background-color: #FFF;
    padding: 1em 3em 3em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 4em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-container {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-header-item {
    padding: 0.5em 0.25em;
    color: var(--text-secondary);
    border-bottom: 1px solid #aaa;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-header-item.pct-item { 
    text-align: center;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-cat-header {
    color: var(--primary-color);
    padding: 0.75em 0.15em 0.25em;
    font-size: 1.25em;
    font-weight: 700;
    grid-column-start: 1;
    grid-column-end: -1;
  } 

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-subcat-header {
    padding: 0 0.25em 0.25em;
    color: var(--primary-color);
    grid-column-start: 1;
    grid-column-end: -1;
  } 

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item{
    padding: 0.35em 0.25em;
    color: var(--text-secondary);

    border-bottom: 1px solid #dfdfdf;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item .small-symbol {
    font-size: 0.8em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item.name-item{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item.pct-item {
    text-align: center;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item.ann-item {
    text-align: center;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-total-item { 
    padding: 0.5em 0.25em 1em;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-total-item .small-symbol {
    font-size: 0.8em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-total-item.pct-item {
    text-align: center;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-total-item { 
    color: var(--primary-color);
  }

  @media ( max-width: 1280px ) { 
    .portfolios-container .portfolios-grid {
      grid-template-columns: 1fr;
    }
    .portfolios-content {
      padding: 0 0em 0 0em;
      font-size: 0.8em;
    }
    .portfolios-container * .portfolios-sidebar-select-account-container {
      grid-template-columns: 260px 1fr;
      max-width: 100%;
      min-height: 0;
    }
    .portfolios-container .portfolios-date-picker-container {
      padding-left: 2em;
      max-width: 175px;
      font-size: 1.1em;
      padding-top: 0.6em;
    }
    .portfolios-container .portfolios-sidebar .portfolios-date-picker-input {
      font-size: 1em;
    }
    .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-container {
      grid-template-columns: 2fr 1fr 1fr;
    }
  }
@media ( max-width: 768px ) {
  .portfolios-container {
    font-size: 0.8em;
  }
  .portfolios-content {
    font-size: 1em;
  }

  .portfolios-grid .portfolios-content-container .portfolios-content-summary-content {
    grid-template-columns: 425px auto;
  }
  .portfolios-grid .portfolios-content-container .portfolios-content-summary-content .recharts-wrapper:first-child {
    transform: translateX(-40px);
  }
  .portfolios-grid .portfolios-content-container .portfolios-content-summary-content .recharts-legend-wrapper {
    transform: translateX(-40px);
  }
  .pageheader-container {
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-size: 1.2em;
  }
  .pageheader-container .pageheader-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }
  .pageheader-container .pageheader-welcome {
    padding-top: 0;
    grid-template-rows: 1.85em 1.2em;
    padding-bottom: 10%
  }
  .pageheader-container .pageheader-advisor {
    display: none;
  }
  .pageheader-grid .pageheader-balances .pageheader-balance-item {
    grid-template-columns: 1.2fr 1fr;
  }
  .pageheader-grid .pageheader-balances .pageheader-balance-asof {
    font-size: 0.8em;
    grid-template-columns: 2fr;
  }

  .portfolios-container * .portfolios-sidebar-select-account-container, .dashboard-content-pfsummary-content-container  {
    grid-template-columns: 100%;
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-portfolios-container {
    grid-template-columns: 13% 18% 12% 27% 18% 12%;
  }
  
  .portfolios-container * .portfolios-sidebar-select-account select.form-control {
    width: 85%;
    margin-left: 5%;
    padding: 0%;
  }

  .portfolios-container * .portfolios-sidebar-select-account select.form-control option {
    font-size: 0.8em;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container {
    width: 85%;
    margin-left: 5%;
    padding: 0%;
    margin-right: 25%;
    max-width: 100%;
    font-size: 1.2em;
  }

  .portoflios-content-summary-header, .portoflios-content-details-header, .portoflios-content-holdings-header {
    text-align: left;
    margin-left: 2%
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-content {
    font-size: 0.65em;
    padding: 1em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-content {
    padding: 1em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-container {
    grid-template-columns: 60% 25% 15%;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-cat-header {
    font-size: 0.85em;
  }

  .navbar-inverse .navbar-nav > li > a {
    font-size: 1.5em;
    text-align: left;
    width: 100%;
    margin: 3.5% 3.5% 2% 3.5%;
  }

  .app-header.navbar-inverse .navbar-lang.dropdown > a, .app-header.navbar-inverse .navbar-lang.dropdown {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  .app-header.navbar-inverse .navbar-lang.dropdown {
    text-align: left;
    width: 100%;
    margin: 3.5% 3.5% 2% 3.5%;
  }

  .navbar-nav .open .dropdown-menu {
    width: 100%;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    font-size: 1.5em;
    width: 100%;
    margin: 2%;
  }
  .navbar-inverse .navbar-nav > .active > a {
    width: auto;
  }
}