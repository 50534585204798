.activate-content-container {
    margin: 0 0.75em;
}
.activate-content-container .activate-content-error-container {
    text-align: center;
    margin: 1.25em 0;
    font-size: 1.1em;
    color: #e53935;
}

.activate-content-container .activate-content-password-container > h3,
.activate-content-container .activate-content-verify-container > h3 {
    font-size: 1.25em;
    font-weight : 300;
    text-align: center;
    margin-bottom: 1em;
    cursor: default;
}

.activate-content-container .activate-content-verify-container > h3 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.activate-content-container .password-eye-icon {
    cursor: pointer;
}

.activate-content-container .activate-content-verify-form .form-group {
    margin-bottom: 2em;
}

.activate-content-container .activate-content-password-criteria {
    margin: 1em 0;
}

.activate-content-container .activate-content-terms {
    height: 400px;
    overflow-y: scroll;
    padding: 0.5em;
    font-size: 0.85em;
    border-style: inset;
}

.activate-content-container .activate-content-terms-actions {
    text-align: center;
}

.activate-content-container .activate-content-terms-actions .activate-content-terms-agree {
    margin-bottom: 2em;
}

.activate-content-container .activate-content-terms-actions .activate-content-terms-btns {
    width: 80%;
    margin: 0 auto;
}

.activate-content-container .activate-content-lang-container {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.activate-content-container .activate-content-lang-container .activate-content-title {
    text-align: center;
    font-size: 1.135em;
    padding: 0.25em 0;
}

.activate-content-container .activate-content-lang-container .activate-content-lang-select-btn {
    width: 60%;
    padding: 1em;
    font-size: 1.35em;
    font-weight: 300;
    border: 1px solid #d0d1d2;
    margin: 0.5em auto;
    text-align: center;
}

.activate-content-container .activate-content-lang-container .activate-content-lang-select-btn:hover {
    background-color: #efefef;
    cursor: pointer;
}

.activate-content-redirect-login-link {
    text-align: center;
}

.activate-content-redirect-text {
    text-align: center;
    font-size: 0.9em;
    padding-bottom: 1.5em;
}
