
.App, .app-container {
  padding-top: 51px;
  height: 100%;
  min-height: 100%;
  position: relative;
}

.App .navbar-brand {
  font-weight: bold;
}

.small-symbol {
  font-size: 1em !important;
}
/** Custom Input CSS **/
.form-group span.help-block{
  font-size: 0.85em;
  margin: 0 0 -1em;
}
.form-group input.form-control {
  border: 0px solid transparent;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #e7e7e7;
  line-height: 1;
  padding-bottom: 0;
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-group.has-error input.form-control {
  border-bottom: 2px solid #a94442;
}

.form-group input.form-control:focus {
  border-bottom-color: var(--primary-color);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group select.form-control {
  border: 0px solid transparent;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #e7e7e7;
  line-height: 1;
  padding-bottom: 0px;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--select-down-svg) 96% 70% no-repeat;
  background-size: auto 20%;
  cursor: pointer;
}

.form-group select.form-control:focus {
  border-bottom-color: var(--text-underline);
}

.form-group input.form-control + span.input-group-addon {
  border: 0px solid transparent;
  border-radius: 0;
  box-shadow: none;
  outline: none !important;
  border-bottom: 2px solid #e7e7e7;
  background-color: #FFF;
  color: #9e9e9e;
}

.form-group input.form-control:focus + span.input-group-addon {
  border-bottom-color: var(--primary-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

select::-ms-expand { 
    display: none; /* remove default arrow in IE 10 and 11 */
}

.form-group .control-label {
  font-weight: 400;
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
    select {
        background:none\9;
        padding: 5px\9;
    }
}

/** Placeholder CSS **/
.form-control::placeholder{
  color: #e7e7e7;
}
.form-control::-webkit-input-placeholder { /* Chrome */
  color: #e7e7e7;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #e7e7e7;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #e7e7e7;
  opacity: 1;
}
.form-control:-moz-placeholder { /* Firefox 4 - 18 */
  color: #e7e7e7;
  opacity: 1;
}

/** Custom Button CSS **/
.btn {
  border-radius: 0px;
  outline: none !important;
  border-radius: 1.5em;
}

.btn.btn-default {
  background-color: var(--primary-color);
  color: #FFF;
  border: 1px solid var(--primary-color);
  border-radius: 1.5em;
}

.btn.btn-default:hover {
  background-color: var(--primary-color-dark);
  border: 1px solid var(--primary-color-dark);
}

.btn.btn-inverse {
  background-color: #FFF;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 1.5em;
}

.btn.btn-inverse:hover {
  background-color: #efefef;
  color: var(--primary-color-dark);
  border: 1px solid var(--primary-color-dark);
  border-radius: 1.5em;
}

.btn.btn-inverse-grey {
  background-color: #FFF;
  color: #757575;
  border: 1px solid #a4a4a4;
  border-radius: 1.5em;
}

.btn.btn-inverse-grey:hover {
  background-color: #efefef;
  color: #616161;
  border: 1px solid #8e8e8e;
  border-radius: 1.5em;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: default;
  pointer-events: none;
  background-color: #babcc0;
  border-color:#babcc0;
  color: #5a5a5a;
  opacity: 1;
  border-radius: 1.5em;
}

.btn.btn-red{
  background-color: #ff4c4c;
  color: #FFF;
  border: 1px solid #ff4c4c;
  border-radius: 1.5em;
}

.btn.btn-red:hover{
  background-color: #e04242;
  color: #FFF;
  border: 1px solid #e04242;
  border-radius: 1.5em;
}

.dropdown-menu>.disabled>a:focus, .dropdown-menu>.disabled>a:hover {
  cursor: default;
}
a:focus {
  outline: none
}
/** Loading button default styles **/
button > .loaderBtn.glyphicon {
  margin-right: 0.5em;
  vertical-align: text-top;
  transform: translateY(-100%);
} 

.system-box .alexa-header-icon {
  width: 1.25em;
}

/** Language select dropdown **/
.langselect-container {
  text-align: center;
  margin-top: 1em;
  margin-bottom: -1em;
}
.langselect-container .btn-link:focus, .langselect-container .btn-link:hover, .langselect-container .btn-link{
  color: var(--header-text);
  text-decoration: none;
}
.langselect-container .btn-group.open .dropdown-toggle {
  box-shadow: none;
}
.langselect-container .dropdown-menu {
  min-width: 1.5em;
  border-radius: 0;
}
.langselect-container button{
  border-radius: 0;
  background-color: transparent;
}

/** Loading Placeholder **/
.component-loading-placeholder {
  margin: 2em 0;
}
.component-loading-placeholder > .component-loading-text{
  margin: 0 auto;
  font-size: 2em;
  text-align: center;
  opacity: 0.5;
}
.component-loading-placeholder > .component-loading-text > span {
  display: inline-block;
  width: 1em;
  text-align: left;
}
.table-loading-placeholder > .table-loading-text{
  width: 7em;
  margin: 0 auto;
  font-size: 2em;
  text-align: center;
  opacity: 0.5;
}

/** Default React Tables Styles **/
.ReactTable {
  border: 1px solid #bfbbbb;
  background-color: #fff;
}
.ReactTable * {
  outline: none !important;
}
.ReactTable .rt-table {
  overflow: visible;
}
.ReactTable .rt-thead {
  background-color: #ebebeb;
  border-bottom: 1px solid #bfbbbb;
  font-weight: 600;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ReactTable .rt-tbody{
  overflow: visible;
}
.ReactTable .rt-tbody .rt-tr-group{
  border-bottom: 1px solid #bfbbbb;
}
.ReactTable .rt-tbody .rt-tr-group .rt-td {
  border-right: none;
  padding-right: 1em;
  cursor: default;
}
.ReactTable .-pagination {
  background-color: #ebebeb;
  font-size: 0.8em;
  border-top: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ReactTable .-pagination .-previous {
  padding: 0.5em;
  max-width: 200px;
}

.ReactTable .-pagination .-next {
  padding: 0.5em;
  max-width: 200px;
}
.ReactTable .-pagination button {
  background-color: var(--primary-color);
  color: #FFF;
  border-radius: 0;
}
.ReactTable .-pagination button:not([disabled]):hover{
  background-color: var(--primary-color-dark);
}

/** Context Menu Styling **/
.react-contextmenu.dropdown-menu{
  display: block;
  border-radius: 0;
  z-index: 0;
}
.react-contextmenu.dropdown-menu .react-contextmenu-item{
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.react-contextmenu.dropdown-menu .react-contextmenu-item:hover{
  cursor: pointer;
  background-color: #f5f5f5;
}

/** Submenu support for bootstrap **/
.dropdown-submenu, .dropdown-submenu-left {
  position: relative;
  display: block;
}
@media (min-width: 768px) {
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
  }

  .dropdown-submenu-left .dropdown-menu {
    top: 0;
    right: 100%;
    margin-top: -1px;
  }
}
