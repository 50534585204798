/** Page Container **/
.documents-container * .documents-grid {
  display: grid;
  max-width: 1600px;
  grid-template-columns: 350px 1fr;
  grid-template-rows: auto 1fr;
  margin: 0 auto;
  border: none;
}

/** Sidebar **/
.documents-container .documents-sidebar {
  padding-right: 3em;
}

.documents-container .documents-sidebar-type-container {
  padding: 2.125em 0 1em;
  width: 100%;
}

.documents-container .documents-sidebar-header {
  color: var(--primary-color);
  font-size: 1.25em;
  font-weight: 500;
  cursor: default;
  padding-bottom: 0.5em;
}

.documents-container .documents-sidebar-type-container .document-type-item {
  color: var(--text-secondary);
  padding: 0.125em 0;
  font-size: 1em;
}

.documents-container
  .documents-sidebar-type-container
  .document-type-item
  span {
  cursor: pointer;
}

.documents-container
  .documents-sidebar-type-container
  .document-type-item.active
  span {
  color: var(--primary-color);
  cursor: default;
}

.documents-container .documents-sidebar-upload-container {
  padding-left: 1.75em;
  padding-top: 0.25em;
}

.documents-container
  .documents-sidebar-upload-container
  .documents-sidebar-upload-btn {
  font-size: 0.85em;
  padding: 0.25em 1em;
  background-color: transparent;
}

/** Page Content **/
.documents-container .documents-content-header {
  color: var(--primary-color);
  font-size: 1.25em;
  font-weight: 500;
  cursor: default;
  padding-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.documents-container
  .documents-content-document-list
  .documents-content-document-header-container {
  background-color: #fff;
  padding: 1em 1em 2em;
  box-shadow: 0px 1px 1px 0px #eaeaea;
  border-radius: 0.3em;
}

.documents-container
  .documents-content-document-list
  .documents-content-document-list-header {
  display: grid;
  grid-template-columns: 5% 27% 27% 27% auto;
  margin: 0 0 0.75em 0.25em;
  border-bottom: 1px solid #aaa;
}

.documents-container
  .documents-content-document-list
  .document-list-header-item {
  padding: 0.5em 0.25em 0.1em;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
}

.documents-container
  .documents-content-document-list
  .document-list-header-item .checkbox {
  padding: 0.5em;
}


.documents-container
  .documents-content-document-list
  .documents-content-document-month-container {
  background-color: #fff;
  padding: 0.5em 1em 2em 0;
  margin-bottom: 1em;
  box-shadow: 0px 1px 1px 0px #eaeaea;
  border-radius: 0.3em;
}

.documents-container .documents-content-document-list .document-list-item {
  display: grid;
  grid-template-columns: 6% 27% 27% 27% auto auto;
  padding: 0 0 0.25em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field {
  padding: 0.75em 0;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field
  .checkbox {
  margin-top: 0;
  padding-left: 1em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .uploaded_date-field {
  opacity: 1;
  cursor: default;
  font-weight: 300;
  border-bottom: 1px solid transparent;
}

.documents-container
  .documents-content-document-list
  .document-list-item:first-child
  .uploaded_date-field {
  opacity: 1;
}

@media (max-width: 915px) {
  .row.layout-header.fill {
    margin-left: 0;
  }
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .display_name-field
  .document-list-item-field-subtitle {
  opacity: 0.5;
  font-size: 0.9em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .status-field
  span {
  vertical-align: middle;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .status-field
  .document-list-item-field-subtitle {
  font-size: 0.9em;
  display: inline-block;
  margin-left: 0.5em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field.download_field
  svg {
  opacity: 0.5;
  cursor: pointer;
  font-size: 1.2em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field.download_field
  svg:hover {
  opacity: 0.75;
  color: var(--primary-color);
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field.delete_field
  svg {
  opacity: 0.25;
  cursor: pointer;
  font-size: 1.2em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field.delete_field
  svg:hover {
  opacity: 0.75;
  color: var(--danger-text);
}

#popover-delete-document {
  min-width: 300px;
}
#popover-delete-document .popover-delete-document-btn-container {
  margin-top: 1em;
  text-align: center;
}

.documents-container
  .documents-content-document-list
  .documents-content-document-no-data {
  padding: 1em 1em;
  opacity: 0.75;
}

/** Modal **/
.document-upload-modal .modal-header {
  background-color: var(--primary-color);
}
.document-upload-modal .modal-title {
  color: var(--header-text);
}

.document-upload-modal .document-upload-input .form-group {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
}

.document-upload-modal .document-upload-input .form-group .sv-input-label {
  color: var(--text-secondary);
}

.document-upload-modal .document-upload-input .form-group .sv-input {
  padding-left: 1em;
  padding-right: 1em;
}
.document-upload-modal .document-upload-input .form-group .help-block {
  margin-top: 0.2em;
  opacity: 0.7;
  font-size: 0.7em;
}

.document-upload-modal
  .document-upload-input
  .document-upload-dropzone-container {
  text-align: center;
  margin: 1.5em 3em 1em;
}

.document-upload-modal .document-upload-input .dropzone-prop-container {
  padding: 2em 0;
  border: 1px dashed var(--text-secondary);
}

.document-upload-modal .document-upload-input .dropzone-prop-container.active {
  border-color: var(--primary-color);
}

.document-upload-modal .document-upload-input .dropzone-prop-container p {
  margin-bottom: 0;
  cursor: default;
}

.document-upload-modal .document-upload-input .dropzone-prop-container-image {
  margin-bottom: 0;
  font-size: 5em;
  color: var(--text-secondary);
  cursor: default;
}

.document-upload-modal
  .document-upload-input
  .dropzone-prop-container-result-image {
  margin-bottom: 0;
  font-size: 5em;
  color: var(--text-secondary);
  cursor: default;
}

.document-upload-modal
  .document-upload-input
  .old-document-upload-dropzone-container {
  text-align: center;
  font-size: 0.85em;
  cursor: default;
}

.document-upload-modal
  .document-upload-input
  .old-document-upload-dropzone-container
  span {
  color: var(--primary-color);
  cursor: pointer;
}

.documents-container .dropdown.btn-group {
  width: 100%;
}

.document-upload-modal .document-modal-btn-container {
  margin: 2em 0 1em;
  text-align: center;
}
.document-upload-modal .document-modal-btn-container button {
  margin: 0 1em;
  font-size: 1.125em;
  padding-left: 1em;
  padding-right: 1em;
}
.document-upload-modal .document-modal-upload-status-container {
  padding: 2em 0;
  border: 1px dashed var(--text-secondary);
  text-align: center;
  color: green;
  margin: 1.5em 3em 1em;
}

.document-upload-modal
  .document-upload-dropzone-container.complete
  .document-modal-upload-status-container {
  padding: 1em 0;
  font-size: 2em;
  margin: 0.75em 1.5em 0.5em;
  border: 1px dashed transparent;
}

.document-upload-modal .document-modal-upload-status-container.error {
  color: red;
}

.document-upload-modal .dropzone-prop-container-accepted span {
  margin: 0.25em 0;
  font-size: 0.8em;
  opacity: 0.8;
  cursor: pointer;
}
.document-upload-modal .dropzone-prop-container-accepted span:hover {
  opacity: 1;
  color: var(--primary-color);
}
#accepted-file-popover {
  max-width: 300px;
}
#accepted-file-popover .popover-content span {
  font-size: 0.8em;
  color: var(--text-secondary);
}

.smallScreen {
  display: none;
}

.largeScreen {
  display: block;
}

.display_name-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1000px) {
  .documents-container * .documents-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    padding-left: 5%;
  }

  .documents-container .documents-sidebar-upload-container {
    padding-left: 0;
  }

  .documents-container .documents-sidebar-type-container .document-type-item {
    color: var(--text-secondary);
    padding: 0.125em 0;
    font-size: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }

  .documents-container .documents-content-document-list .document-list-item {
    display: grid;
    grid-template-columns: 10% 20% 50% 10% 5% 5%;
    padding: 0 0.25em;
    font-size: 0.7em;
  }

  .document-list-item-field {
    margin-right: 0.75em;
  }

  .documents-container
    .documents-content-document-list
    .documents-content-document-month-container {
    background-color: #fff;
    padding: 0.3em;
    margin-bottom: 1em;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
  }

  .documents-container .documents-sidebar {
    padding-right: 3em;
    padding-bottom: 5%;
  }

  .documents-content-document-item-container {
    width: 100%;
  }

  .documents-container
    .documents-content-document-list
    .documents-content-document-list-header {
    margin: 0%;
    width: 100%;
    grid-template-columns: 10% 20% 50% 10% auto auto;
    font-size: 0.9em;
  }

  /* For Mobile Design */
  .smallScreen {
    display: block;
  }

  .largeScreen {
    display: none;
  }

  #documentMobileOption:focus {
    border-bottom-color: var(--text-underline);
  }

  #documentMobileOption {
    border: 0px solid transparent;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 2px solid #e7e7e7;
    line-height: 1;
    padding-bottom: 0px;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: var(--select-down-svg) 96% 70% no-repeat;
    background-size: auto 20%;
    cursor: pointer;
    padding: 0% 0% 2% 5%;
    width: 100%;
    text-align: left;
  }

  .dropzone-prop-container-accepted.smallScreen {
    text-align: center;
  }

  .pageheader-container .pageheader-welcome-message,
  .pageheader-grid
    .pageheader-balances
    .pageheader-balance-item
    .pageheader-balance-item-value,
  .documents-container .documents-content-header {
    font-size: 0.85em;
  }

  .pageheader-container .pageheader-balances .pageheader-balance-item-header {
    font-size: 0.7em;
  }

  @media (max-width: 500px) {
    .documents-container .documents-content-document-list .document-list-item {
      grid-template-columns: 10% 28% 28% 23% auto auto;
    }
    .documents-container
      .documents-content-document-list
      .documents-content-document-list-header {
      grid-template-columns: 10% 27% 27% 27% auto;
    }
  }
}
