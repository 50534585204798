    .accountsettings-container * .accountsettings-grid {
        display: grid;
        max-width: 1600px;
        grid-template-columns: 1fr minmax(100%, 1000px) 1fr;
        grid-template-rows: auto 1fr;
        margin: 0 auto;
        border: none;
    }

    .accountsettings-container .accountsettings-info-container {
        background-color: #FFF;
        padding-left: 1em;
        padding-right: 2em;
        padding-top: 1em;
        padding-bottom: 2em;
        width: 100%;
        max-width: 1000px;
        -webkit-box-shadow: 0px 1px 1px 0px #eaeaea;
        -moz-box-shadow: 0px 1px 1px 0px #eaeaea;
        box-shadow: 0px 1px 1px 0px #eaeaea;
        border-radius: 0.3em;
        display: grid;
        grid-template-columns: 110px auto;
        margin: 0 auto;
    }

    .accountsettings-container .accountsettings-info-container .accountsettings-info-image img{
        border-radius: 50%;
        height: 5em;
        width: 5em;
        object-fit: cover;
        background-color: #eee;
    }

    .accountsettings-container .accountsettings-info-container .accountsettings-info-image svg{
        width: 80%;
        height: 5em;
        color: #E2DEDD;
    }

    .accountsettings-container .accountsettings-info-container .accountsettings-info-image svg.fa-circle-notch{
        color: var(--primary-color);
        opacity: 0.75;
    }

    .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-img {
        margin: 0 auto;
        text-align: center;
    }  
    .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-container {
        margin-top: 1em;
        text-align: center;
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-container .accountsettings-change-avatar-text {
        color: var(--text-secondary);
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-btn {
        font-size: 0.7em;
    }  

    .accountsettings-container .accountsettings-info-container .accountsettings-info-content {
        padding-left: 1em;
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-info-name {
        font-size: 2em;
        font-weight: 300;
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-info-email{
        font-size: 1.25em;
        color: var(--text-secondary);
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-info-notifications {
        margin-top: 2em;
        max-width: 600px;
    }
    .accountsettings-container .accountsettings-info-notifications .accountsettings-notifications-header {
        color: var(--text-secondary);
        border-bottom: 1px solid #dfdfdf;
        padding-right: 1em;
    }
    .accountsettings-container .accountsettings-notifications-mobile {
        display: grid;
        grid-template-columns: auto auto 1fr;
        align-items: center;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-header {
        padding: 0.5em 0 0.25em;
        font-weight: 300;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-toggle-container {
        padding: 0.25em 0.5em 0;
    }  
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle {
        font-size: 1em;
        margin-top: 0.3em;
        color: #aaa;
    }   
    .accountsettings-container .accountsettings-notifications-mobile .react-phone-number-input__icon {
        border: 0px solid transparent;
    }
    .accountsettings-container .accountsettings-notifications-mobile .react-phone-number-input__icon img{
        vertical-align: baseline;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg {
        margin-right: 0.5em;
        font-size: 0.8em;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-check.disabled{
        color: #dfdfdf;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-pencil:hover{
        color: var(--primary-color);
        cursor: pointer;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-check.disabled:hover{
        color: #dfdfdf;
        cursor: default;
        pointer-events: none;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-check:hover{
        color: var(--primary-color);
        cursor: pointer;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-times:hover{
        color: var(--danger-text);
        cursor: pointer;
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms {
        display: grid;
        grid-template-columns: auto 2.25em 1fr;
        align-items: center;
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms .accountsettingsnotifications-notifications-sms-toggle svg {
        width: 100%;
        height: 1em;
        padding-left: 1.25em;
        cursor: pointer;
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms .accountsettingsnotifications-notifications-sms-toggle .fa-square{
        color: var(--text-secondary);
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms .accountsettingsnotifications-notifications-sms-toggle .fa-check-square{
        color: var(--primary-color);
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms .accountsettingsnotifications-notifications-sms-message {
        padding: 0.25em 0 0.25em;
        font-weight: 300;
    }
    @media ( max-width: 768px ) {
        .accountsettings-container {
          font-size: 0.8em;
        }
        .accountsettings-container .accountsettings-info-container {
            display: grid;
            grid-template-columns: auto;
        }

        .accountsettings-container .accountsettings-info-container .accountsettings-info-image {
            cursor: pointer;
        }
        .accountsettings-container .accountsettings-info-container .accountsettings-info-image svg {
            height: 10em;
        }

        .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-btn {
            font-size: 1.3em;
            margin-bottom: 2em;
        }

        .accountsettings-container .accountsettings-info-container .accountsettings-info-content {
            font-size: 0.85em;
        }

        .accountsettings-container .accountsettings-info-container .accountsettings-info-name {
            font-size: 1.5em;
        }
    }