html, body {
  margin: 0;
  display: table;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  font-size: 14px;
  
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: 16px;
  color: #333;
  overflow-x: hidden;
  background-color: #FFF;
}

#root{
  height: 100%;
  min-height: 100%;
  display: table-cell;
}

/** MobileOverflow Fix for React-Bootstrap **/
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

/** Unselectable styles **/
.unselectable{
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.unselectable * {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/** div cursor default override **/
div {
  cursor: default;
}

/** Animated Spinner Styles **/
.spinning {
  padding-top: 1px;
  animation: spin 1s infinite linear;
}
.spinning.slow {
  animation: spin 1.5s infinite linear;
}
.hover-spin:hover {
  margin-top: -2px;
  padding-top: 2px;
  animation: spin 1s infinite linear;
}
.fa-ease-in-out-spin{
  -webkit-animation: fa-spin 2s infinite ease-in-out;
  animation: fa-spin 2s infinite ease-in-out;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
