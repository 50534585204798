/** Page Container **/
.performance-container * .performance-grid {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 260px 1fr;
    grid-template-rows: auto 1fr;
    margin: 0 auto;
    border: none;
  }

/** Sidebar content **/
  .performance-grid .performance-sidebar .performance-sidebar-group-header{
    color: var(--text-secondary);
    font-size: 1.25em;
  }
  .performance-grid .performance-sidebar .performance-sidebar-nav-groups-loading svg{
    color: var(--primary-color);
  }
  .performance-grid .performance-sidebar .performance-sidebar-group-container {
    padding: 0.25em 0;
  }

  .performance-grid .performance-sidebar .performance-sidebar-group-container .performance-sidebar-group-item {
    padding: 0.25em 0;

  }

  .performance-grid .performance-sidebar .performance-sidebar-group-container .performance-sidebar-group-item span{
    cursor: pointer;
    color: var(--primary-color)
  }

  .performance-grid .performance-sidebar .performance-sidebar-group-container .performance-sidebar-group-item span:hover {
    color: var(--primary-color-dark)
  }


/** Perforamnce Content **/  
  .performance-content {
    padding: 0 0em 0 5.75em;
  }
  .performance-content .performance-content-group {
    padding-bottom: 2.5em;
    margin-bottom: 2em;
  }
  .performance-content .performance-content-group:last-child {
    padding-bottom: 2.5em;
    margin-bottom: 0em;
  }
  .performance-content .performance-content-header{
    color: var(--primary-color);
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 0.75em;
    border-bottom: 1px solid #dfdfdf;
  }

  .performance-content .performance-content-group-header {
    color: var(--text-secondary);
    font-size: 1em;
    font-weight: 500;
    display: inline-block;
    padding-left: 0.5em;
  }

  .performance-content .performance-annual-returns-header{
    color: var(--primary-color);
    font-size: 1.1em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .performance-content .performance-annual-returns-header .performance-content-returns-sub-header {
    color: var(--text-secondary);
    font-size: 0.85em;
    font-weight: 500;
    display: inline-block;
    padding-left: 0.5em;
  }

  .performance-content .performance-content-compound-returns-container {
    margin-bottom: 2em;
  }

  .performance-content .performance-annual-returns-content{
    background-color: #FFF;
    padding: 2em 3em 3em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 2em;
    display: grid;
    grid-template-columns: auto; 
    align-items: center;
  }

  .performance-content .performance-annual-returns-content .y-axis-label {
    fill: var(--text-secondary);
    text-anchor: end;
    alignment-baseline: text-before-edge;
    font-size: 0.8em;
    font-weight: 300;
  }

  .performance-content .performance-annual-returns-content .y-axis-line {
      stroke: var(--text-secondary);
      stroke-width: 1;
      shape-rendering: crispEdges;
    }

  .performance-content .performance-annual-returns-content .recharts-reference-line-line {
      shape-rendering: crispEdges;
  }

  .performance-content .performance-annual-returns-content .recharts-rectangle {
      shape-rendering: crispEdges;
  }


  .performance-content .performance-annual-returns-content .yAxis text {
        fill: var(--text-secondary);
        font-weight: 300;
        font-size: 0.85em;
  }

  .performance-content .performance-annual-returns-content .xAxis text {
        fill: var(--text-secondary);
        font-weight: 300;
        font-size: 0.85em;
  }

  .performance-content .performance-annual-returns-content .bar-label-text {
      font-size: 0.7em;
  }

  .performance-content .performance-compound-returns-header{
    color: var(--primary-color);
    font-size: 1.1em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .performance-content .performance-compound-returns-header .performance-content-compound-returns-sub-header {
    color: var(--text-secondary);
    font-size: 0.85em;
    font-weight: 500;
    display: inline-block;
    padding-left: 0.5em;
  }
  .performance-content .performance-compound-returns-content{
    background-color: #FFF;
    padding: 1em 3em 2em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
  }

  .performance-content .performance-compound-returns-headers {
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 0.8fr 1fr;
    color: var(--text-secondary);
    text-align: center;
    padding-bottom: 0.5em;
  }

  .performance-content .performance-compound-returns-headers .performance-compound-returns-header-item.ytd-item {
    text-align: left;
  }

  .performance-content .performance-compound-returns-headers .cp-item {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 0.25em 0;
    font-size: 0.9em;
  }

  .performance-content .performance-compound-returns-items {
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 0.8fr 1fr;
    padding: 0.5em 0;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    text-align: center;
    font-weight: 300;
  }
  .performance-content .performance-compound-returns-items .performance-compound-returns-item .small-symbol{
    font-size: 0.75em;
  }
  .performance-content .performance-compound-returns-items .performance-compound-returns-item:first-child{
    text-align: left;
  }

  .performance-content .performance-portfolios-content{
    background-color: #FFF;
    padding: 1em 3em 1.5em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 2em;
  }

  .performance-content .performance-portfolios-header{
    color: var(--primary-color);
    font-size: 1em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }


  .performance-content .performance-portfolios-content .component-loading-placeholder .component-loading-text {
    font-size: 1em;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-header{
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 1fr 0.6fr 0.3fr;
    padding: 0.5em 0.25em;
    color: var(--text-secondary);
    border-bottom: 1px solid #aaa;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-header .curr-item {
    text-align: center;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-item{
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 1fr 0.6fr 0.3fr;
    font-weight: 300;
    border-bottom: 1px solid #dfdfdf;
    padding: 0.5em 0.25em;
  }
  .performance-content .performance-portfolios-content .performance-portfolios-content-item:last-child {
    border-bottom: 0px solid transparent;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-item .name-item { 
    font-weight: 400;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-item .curr-item {
    text-align: center;
  }
  .performance-content .performance-group-scroll-to-top {
    margin: 1em 0 0;
  }

  .performance-content .performance-group-scroll-to-top span {
    color: var(--text-secondary);
    opacity: 0.8;
    cursor: pointer;
    font-size: 0.8em;
  }
  .performance-content .performance-group-scroll-to-top span:hover {
    opacity: 1;
  }

  @media ( max-width: 1280px ) { 
    .performance-container * .performance-grid {
      grid-template-columns: 260px 1fr;
      max-width: 100%;
    }
    .performance-content {
      font-size: 0.7em;
      padding-left: 2em;
      max-width: 100%;
    }
    .performance-content .performance-annual-returns-content{
      max-width: 100%;
    }
  }
  @media ( max-width: 767px ) { 
    .performance-container * .performance-grid {
      grid-template-columns: 1fr;
      max-width: calc(767px - 6em);
    }
    .performance-grid .performance-sidebar .performance-sidebar-group-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0.25em 2em 2em;
    }
    .performance-container .performance-content {
      padding-left: 0em;
      max-width: 100%;
    }
    .performance-content .performance-annual-returns-content {
      max-width: 100%;
    }

    .performance-annual-returns-header, .performance-sidebar-group-header, .performance-compound-returns-header, .performance-content-header, .performance-portfolios-header {
      margin-left: 2%;
    }

    .performance-content .performance-group-scroll-to-top {
      font-size: 1.5em;
      margin-left: 3%;
    }

    .performance-content .performance-compound-returns-content, .performance-content .performance-portfolios-content, .performance-content .performance-annual-returns-content {
      padding: 1em;
    }

    .performance-content .performance-content-header {
      font-size: 1.1em;
    }

    .performance-content .performance-portfolios-content .performance-portfolios-content-header, .performance-content .performance-portfolios-content .performance-portfolios-content-item {
      grid-template-columns: 21% 20% 45% 14%;
    }

    .performance-content .performance-compound-returns-items, .performance-content .performance-compound-returns-headers {
      grid-template-columns: 13% 12% 16% 16% 16% 30%;
    }
  }
