
.login-content .content-container {
  background-color: #fff;
  border: 0px solid #d6d6d6;
  padding: 2em 2em;
  max-width: 600px;
  margin: 0 auto;
  -webkit-box-shadow: 0 4px 20px 0 rgba(18, 20, 24, 0.5);
  box-shadow: 0 4px 20px 0 rgba(18, 20, 24, 0.5);
  border-radius: 1em;
  width: 85%;
}
.login-pat-text.form-group{
  font-size: 0.8em;
  text-align: center;
  opacity: 0.6;
  margin-bottom: 0.5em;
  cursor: default;
}
.login-pat-text.form-group * a {
  cursor: pointer;
}
.login-submit-btn{
  font-size: 1.5em;
}
.login-subcontainer {
  text-align: center;
  margin-top: 1em;
  cursor: default;
}
.login-subcontainer * a {
  cursor: pointer;
}
.loginButton{
  margin-top: 1.5em;
}
.login-content .password-eye-icon {
  float: right;
  margin-right: 0.25em;
  margin-top: -1.5em;
  position: relative;
  z-index: 2;
  color: #9e9e9e;
}
.login-content .login-lang-select{
  text-align: center;
  margin-top: 1em;
  margin-bottom: -1em;
}

.login-content .sub-container {
  color: #FFF;
}

.login-content .sub-container a {
  color: #fff;
  text-decoration: underline;
}

.login-content .sub-container a:hover {
  color: #babcc0;
}

