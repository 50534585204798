.pageheader-container {
    background-color: #FFF;
    padding: 0.85em 3em 0.75em;
    font-size: 0.85em;
}

.pageheader-container .pageheader-grid{
    display: grid;
    grid-template-columns: 350px 1fr auto;
    max-width: 1600px;
    margin: 0 auto;
}

.pageheader-container .pageheader-grid .pageheader-balance-item.fee-pct-item{
    padding-left: 3em;
}

.pageheader-container .pageheader-welcome{
    display: grid;
    grid-template-rows: 1.85em 1em;
    padding-top: 1.45em;
}

.pageheader-container .pageheader-welcome-message {
    color: var(--text-secondary);
    font-size: 1.25em;
    cursor: default;
}

.pageheader-container .pageheader-welcome-notification {
    color: var(--primary-color);
    font-size: 0.875em;
    cursor: default;
}
.pageheader-container .pageheader-welcome-notification span:hover {
    cursor: pointer;
}

.pageheader-container .pageheader-balances {
    padding: 0 0em;
    display: grid;
    grid-template-columns: auto auto auto auto auto 1fr;
    grid-template-rows: auto auto;
    grid-template-rows: auto auto;
    align-items: end;
}  
.pageheader-container .pageheader-balances .pageheader-balance-symbol {
    font-size: 1.5em;
    color: var(--primary-color);
    font-weight: 300;
    cursor: default;
}

.pageheader-container .pageheader-balances .pageheader-balance-item {
    padding: 0 1em 0 1em;
}

.pageheader-container .pageheader-balances .pageheader-balance-item .small-symbol{
    font-size: 0.8em;
}

.pageheader-container .pageheader-balances .pageheader-balance-item:first-of-type {
    padding-left: 0;
}

.pageheader-container .pageheader-balances .pageheader-balance-item:nth-child(3) {
    border-right: 0px solid transparent;
    padding-right: 0.75em;
}

.pageheader-container .pageheader-balances .pageheader-balance-item-header {
    color: var(--primary-color);
    font-size: 0.85em;
    cursor: default;    
}

.pageheader-container .pageheader-balances .pageheader-balance-item-value {
    font-size: 1.5em;
    cursor: default;
    color: var(--text-secondary);
}
.pageheader-container .pageheader-balances .pageheader-balance-item-value-highlight {
    color: var(--primary-color);
}

.pageheader-container .pageheader-balances .pageheader-balance-asof {
    color: var(--text-secondary);
    font-size: 0.8em;
    padding-left: 0.25em;
    cursor: default;
    grid-column: 5;
    padding-left: 1.35em;
}

.pageheader-container .pageheader-advisor{
    display: grid;
    grid-template-columns: 1fr auto;
    padding-top: 0.5em;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    max-height: 50px;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info-image {
    padding: 0 0.5em;
    grid-row-start: 1;
    grid-row-end: -1;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info-image svg{
    width: 2.75em;
    height: 100%;
    margin-right: 0.75em;
    color: #E2DEDD;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info-image img{
    width: 50px;
    height: 50px;
    margin-right: 0.75em;
    border-radius: 50%;
    object-fit: cover;
    background-color: #eee;
}


.pageheader-container .pageheader-advisor .pageheader-advisor-info-name {
    color: var(--text-secondary);
    font-size: 1.25em;
    cursor: default;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info-contact {
    color: var(--primary-color);
    font-size: 0.875em;
    cursor: pointer;
    display: inline-block;
}

#pageheader-popover-contact {
    max-width: 350px;
    font-size: 0.8em;
}

#pageheader-popover-contact .pageheader-popover-contact-header {
    display: block;
    color: var(--text-secondary);
}

#pageheader-popover-contact .pageheader-popover-contact-value a {
    display: block;
    color: #333;
    text-decoration: none !important;
}

#pageheader-popover-contact .pageheader-popover-contact-value a:hover {
    color: var(--primary-color);
}

@media ( max-width: 1280px ) {
    .pageheader-grid .pageheader-balances {
        display: block;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-symbol {
        display: none;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-item {
        padding: 0 0 !important;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: baseline;
    }
    .pageheader-grid .pageheader-balance-item.fee-pct-item{
        display: none;
    }
    .pageheader-container .pageheader-balances .pageheader-balance-item .pageheader-balance-item-header {
        padding-bottom: 2px;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-item .pageheader-balance-item-value {
        text-align: right;
        font-size: 1.25em;
    }

    .pageheader-container .pageheader-balances .pageheader-balance-item:nth-child(3) .pageheader-balance-item-header, 
    .pageheader-container .pageheader-balances .pageheader-balance-item:nth-child(3) .pageheader-balance-item-value{
        border-bottom: 1px solid #dfdfdf;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-asof {
        padding: 0 0;
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: baseline;
        grid-column-start: 2;
        text-align: right;
    }
}
@media ( max-width: 1024px ) { 
    .pageheader-container .pageheader-grid{
        display: grid;
        grid-template-columns: 300px 1fr auto;
        max-width: 1600px;
        margin: 0 auto;
    }
}

@media ( max-width: 768px ) {
    .pageheader-container {
        padding-left: 1.5em;
        padding-right: 1.5em;
        font-size: 1em;
    }
    .pageheader-container .pageheader-grid {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
    }
    .pageheader-container .pageheader-welcome {
        padding-top: 0;
        grid-template-rows: 1.85em 1.2em;
        padding-bottom: 10%
    }
    .pageheader-container .pageheader-advisor {
        display: none;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-item {
        grid-template-columns: 1.2fr 1fr;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-asof {
        font-size: 0.8em;
        grid-template-columns: 2fr;
    }
}

/* @media ( max-width: 991px ) {
    .pageheader-container {
        font-size: 0.8em;
    }
    .pageheader-container .pageheader-grid{
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
        max-width: 1600px;
        margin: 0 auto;
    }
    .pageheader-container .pageheader-balances {
        grid-row: 3;
        grid-column-start: 1;
        grid-column-end: -1;
    }
}
@media ( max-width: 450px ) {
    .pageheader-container .pageheader-grid{
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        max-width: 1600px;
        margin: 0 auto;
    }
    .pageheader-container .pageheader-advisor{
        display: none;
    }
}
 */
