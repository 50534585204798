.app-header .header-logo{
    margin-top: 0.5em;
    max-height: 35px;
    margin-bottom: 0.5em;
}

.app-header .navbar-lang > .dropdown-menu, .app-header .navbar-lang > .dropdown-toggle{
    min-width: 65px;
    border-radius: 0;
}

.app-header .navbar-lang > .dropdown-toggle{
    text-align: left;
}

.app-header .navbar-lang.open>a, .app-header .navbar-lang.open>a:focus, .app-header .navbar-lang.open>a:hover {
    background-color: var(--header-color-dark);
}

.app-header.navbar-inverse .navbar-collapse .open>a, .app-header.navbar-inverse .navbar-collapse .open>a:focus, .app-header.navbar-inverse .navbar-collapse .open>a:hover {
    text-decoration: underline;
    color: var(--header-text);
    background-color: var(--header-color);
}

.app-header .navbar-lang .dropdown-menu>li>a:focus, .app-header .navbar-lang .dropdown-menu>li>a:active, .app-header .navbar-lang .dropdown-menu>li>a:hover {
    color: #333;
}

.app-header.navbar-inverse .navbar-collapse, .app-header.navbar-inverse .navbar-form{
    max-width: 1600px;
    padding-right: 0.5em;
    margin: 0 auto;
    border-color: var(--header-color-dark);
}

.app-header .navbar-toggle{
    border: 0 solid transparent;
    margin-top: 1em;
    background-color: var(--header-color);
} 
.app-header .navbar-toggle .icon-bar {
    background-color: var(--header-text);
}
.app-header .navbar-toggle:hover, .app-header .navbar-toggle:active , .app-header .navbar-toggle:active {
    background-color: var(--header-color-dark);
}
.app-header .navbar-icon-sm-text{
    display: none;
}
.app-header .navbar-alerts a, .app-header .navbar-settings > a{
    font-size: 1.25em;
}
.app-header li.disabled {
    opacity: 0.5;
}

.app-header .dropdown-menu>li>a {
    font-weight: 6300;
}

@media (max-width: 767px) {
    .app-header.navbar-inverse .navbar-collapse, .app-header.navbar-inverse .navbar-form{
        margin-right: -15px;
        margin-left: -15px;
    }
    .app-header .navbar-lang > .dropdown-toggle{
        text-align: left;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: var(--header-text);
    }
    .app-header .navbar-lang .dropdown-menu>li>a {
        color: var(--header-text);
    }
    .app-header .header-logo{
        margin-left: 0.5em;
    }
    .app-header .navbar-icon-sm-text{
        display: inline-block;
    }
    .app-header .navbar-icon{
        display: none;
    }
    .app-header .navbar-alerts a, .app-header .navbar-settings > a{
        font-size: 1em;
    }
    .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
        max-height: 100%;
    }
}
@media ( max-width: 991px ) and ( min-width: 767px ) {
    .navbar-inverse .system-box {
        font-size: 0.8em;
        padding-top: 0.8em;
    }
}
@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus, .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
        text-decoration: underline;
        color: var(--header-text);
    }
}
/** Navbar Default override **/
.navbar-inverse{
    background-color: #3c434d;
    border-color: #3c434d;
    color: var(--header-text);
}
.navbar-inverse .navbar-brand {
    color: var(--header-text);
}
.navbar-inverse .navbar-brand > img {
    padding: 0 0;
    max-height: 40px;
}
.navbar-inverse .navbar-nav>li>a {
    color: var(--header-text);
    font-size: 1.125em;
    font-weight: 400;
}
.navbar-inverse .navbar-nav .nav-logout-btn > a {
    border: 1px solid var(--header-color);
    margin: 0.5em;
}
.navbar-inverse .navbar-nav>li>a:focus, .navbar-inverse .navbar-nav>li>a:hover {
    color: var(--header-text);
}
@media (min-width: 768px){
    .navbar-inverse .navbar-nav .nav-logout-btn > a {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-right: 5px;
    }
    .navbar-spacer-left{
      margin-left: 3em;
    }
    .app-header .navbar-nav>li.navbar-alerts>a, .app-header .navbar-nav>li.navbar-settings > a{
        font-size: 1.375em;
        margin: 0.775em 0.5em 0.375em;
    }
    .app-header .navbar-nav>li.navbar-auth-text>a {
        font-size: 1em;
        margin-top: 1.1em; 
    }
    .app-header .system-box {
        font-size: 0.85em;
        padding-top: 0.45em;
    }
}
  .navbar-nav>li>a {
      padding: 0 0 0.1875em;
      margin: 1em 0.9375em 0.375em;
  }
  .navbar-inverse .navbar-nav>li>a:hover {
    color: var(--primary-color-dark);
  }
  .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
    color: var(--header-text);
    background-color: var(--header-color);
  }

  .navbar-inverse .navbar-nav>.active>a {
    border-bottom: 1px solid var(--text-underline);
    color: var(--header-text);
    background-color: var(--header-color);
  }
  .app-header.navbar-inverse .navbar-lang.dropdown{
      max-width: 2.5em;
  }
  .app-header.navbar-inverse .navbar-lang.dropdown>a{
      margin-left: 0.675em;
  }
  @media (max-width: 767px) {
    .app-header.navbar-inverse .navbar-collapse.in  {
        height: calc(100% - 90px);
        overflow-y: scroll;
    }
    .navbar-nav .open .dropdown-menu{ 
        margin-left: 2em;
        border-left: 1px solid var(--header-color-dark);
    }
    .navbar-nav .open .dropdown-menu .dropdown-toggle{
        padding-left: 1em;
    }
    .navbar-nav .open .dropdown-submenu .dropdown-menu, .navbar-nav .open .dropdown-submenu-left .dropdown-menu { 
      margin-left: 2em;
      border-left: 1px solid var(--header-color-dark);
    }
    .navbar-nav .open .dropdown-submenu .dropdown-menu a, .navbar-nav .open .dropdown-submenu-left .dropdown-menu a {
      padding-left: 1em;
    }
    .navbar-nav>li>a {
        display: inline-block;
    }
    .app-header.navbar-inverse .navbar-lang.dropdown>a{
        padding: 0 0 0.1875em;
        margin: 1em 0.9375em 0.375em;
    }
  }
.pageheader-container {
    background-color: #FFF;
    padding: 0.85em 3em 0.75em;
    font-size: 0.85em;
}

.pageheader-container .pageheader-grid{
    display: grid;
    grid-template-columns: 350px 1fr auto;
    max-width: 1600px;
    margin: 0 auto;
}

.pageheader-container .pageheader-grid .pageheader-balance-item.fee-pct-item{
    padding-left: 3em;
}

.pageheader-container .pageheader-welcome{
    display: grid;
    grid-template-rows: 1.85em 1em;
    padding-top: 1.45em;
}

.pageheader-container .pageheader-welcome-message {
    color: var(--text-secondary);
    font-size: 1.25em;
    cursor: default;
}

.pageheader-container .pageheader-welcome-notification {
    color: var(--primary-color);
    font-size: 0.875em;
    cursor: default;
}
.pageheader-container .pageheader-welcome-notification span:hover {
    cursor: pointer;
}

.pageheader-container .pageheader-balances {
    padding: 0 0em;
    display: grid;
    grid-template-columns: auto auto auto auto auto 1fr;
    grid-template-rows: auto auto;
    grid-template-rows: auto auto;
    align-items: end;
}  
.pageheader-container .pageheader-balances .pageheader-balance-symbol {
    font-size: 1.5em;
    color: var(--primary-color);
    font-weight: 300;
    cursor: default;
}

.pageheader-container .pageheader-balances .pageheader-balance-item {
    padding: 0 1em 0 1em;
}

.pageheader-container .pageheader-balances .pageheader-balance-item .small-symbol{
    font-size: 0.8em;
}

.pageheader-container .pageheader-balances .pageheader-balance-item:first-of-type {
    padding-left: 0;
}

.pageheader-container .pageheader-balances .pageheader-balance-item:nth-child(3) {
    border-right: 0px solid transparent;
    padding-right: 0.75em;
}

.pageheader-container .pageheader-balances .pageheader-balance-item-header {
    color: var(--primary-color);
    font-size: 0.85em;
    cursor: default;    
}

.pageheader-container .pageheader-balances .pageheader-balance-item-value {
    font-size: 1.5em;
    cursor: default;
    color: var(--text-secondary);
}
.pageheader-container .pageheader-balances .pageheader-balance-item-value-highlight {
    color: var(--primary-color);
}

.pageheader-container .pageheader-balances .pageheader-balance-asof {
    color: var(--text-secondary);
    font-size: 0.8em;
    padding-left: 0.25em;
    cursor: default;
    grid-column: 5;
    padding-left: 1.35em;
}

.pageheader-container .pageheader-advisor{
    display: grid;
    grid-template-columns: 1fr auto;
    padding-top: 0.5em;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    max-height: 50px;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info-image {
    padding: 0 0.5em;
    grid-row-start: 1;
    grid-row-end: -1;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info-image svg{
    width: 2.75em;
    height: 100%;
    margin-right: 0.75em;
    color: #E2DEDD;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info-image img{
    width: 50px;
    height: 50px;
    margin-right: 0.75em;
    border-radius: 50%;
    object-fit: cover;
    background-color: #eee;
}


.pageheader-container .pageheader-advisor .pageheader-advisor-info-name {
    color: var(--text-secondary);
    font-size: 1.25em;
    cursor: default;
}

.pageheader-container .pageheader-advisor .pageheader-advisor-info-contact {
    color: var(--primary-color);
    font-size: 0.875em;
    cursor: pointer;
    display: inline-block;
}

#pageheader-popover-contact {
    max-width: 350px;
    font-size: 0.8em;
}

#pageheader-popover-contact .pageheader-popover-contact-header {
    display: block;
    color: var(--text-secondary);
}

#pageheader-popover-contact .pageheader-popover-contact-value a {
    display: block;
    color: #333;
    text-decoration: none !important;
}

#pageheader-popover-contact .pageheader-popover-contact-value a:hover {
    color: var(--primary-color);
}

@media ( max-width: 1280px ) {
    .pageheader-grid .pageheader-balances {
        display: block;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-symbol {
        display: none;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-item {
        padding: 0 0 !important;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: baseline;
    }
    .pageheader-grid .pageheader-balance-item.fee-pct-item{
        display: none;
    }
    .pageheader-container .pageheader-balances .pageheader-balance-item .pageheader-balance-item-header {
        padding-bottom: 2px;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-item .pageheader-balance-item-value {
        text-align: right;
        font-size: 1.25em;
    }

    .pageheader-container .pageheader-balances .pageheader-balance-item:nth-child(3) .pageheader-balance-item-header, 
    .pageheader-container .pageheader-balances .pageheader-balance-item:nth-child(3) .pageheader-balance-item-value{
        border-bottom: 1px solid #dfdfdf;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-asof {
        padding: 0 0;
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: baseline;
        grid-column-start: 2;
        text-align: right;
    }
}
@media ( max-width: 1024px ) { 
    .pageheader-container .pageheader-grid{
        display: grid;
        grid-template-columns: 300px 1fr auto;
        max-width: 1600px;
        margin: 0 auto;
    }
}

@media ( max-width: 768px ) {
    .pageheader-container {
        padding-left: 1.5em;
        padding-right: 1.5em;
        font-size: 1em;
    }
    .pageheader-container .pageheader-grid {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
    }
    .pageheader-container .pageheader-welcome {
        padding-top: 0;
        grid-template-rows: 1.85em 1.2em;
        padding-bottom: 10%
    }
    .pageheader-container .pageheader-advisor {
        display: none;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-item {
        grid-template-columns: 1.2fr 1fr;
    }
    .pageheader-grid .pageheader-balances .pageheader-balance-asof {
        font-size: 0.8em;
        grid-template-columns: 2fr;
    }
}

/* @media ( max-width: 991px ) {
    .pageheader-container {
        font-size: 0.8em;
    }
    .pageheader-container .pageheader-grid{
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
        max-width: 1600px;
        margin: 0 auto;
    }
    .pageheader-container .pageheader-balances {
        grid-row: 3;
        grid-column-start: 1;
        grid-column-end: -1;
    }
}
@media ( max-width: 450px ) {
    .pageheader-container .pageheader-grid{
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        max-width: 1600px;
        margin: 0 auto;
    }
    .pageheader-container .pageheader-advisor{
        display: none;
    }
}
 */



/** BOX LAYOUT **/
@media all and (min-width: 480px) {
  .layout-content.box {
    margin: 60px 0 0;
  }

  .layout-content.box form {
    margin: 0 auto;
    max-width: 450px;
  }
}

.logo-container.box {
  text-align: center;
  margin-bottom: 1em;
}

.logo-container.box > img.logo-icon-image {
    height: 2.5em;
    margin-right: 0.25em;
    vertical-align: middle;
}

.logo-container.box > img.logo-text-image {
    vertical-align: middle;
    margin-top: 0.2em;
}

.content-container.box {
  background-color: #fff;
  border: 0px solid #d6d6d6;
  padding: 2em 2em 3em 2em;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 1em;
  box-shadow: 0 4px 20px 0 rgba(18, 20, 24, 0.5);
  width: 85%;
}
.content-container.box .error-container {
  width: 85%;
  margin: 0.5em auto 0;
  font-size: 0.8em;
}
.sub-container.box {
  text-align: center;
  margin-top: 1em;
  cursor: default;
  font-weight: 300;
}

.sub-container.box * a {
  cursor: pointer;
}

/** FLOAT LAYOUT **/
@media all and (min-width: 480px) {
  .layout-content.float {
    margin: 60px 0 0;
  }

  .layout-content.float form {
    margin: 0 auto;
    max-width: 450px;
  }
}

.logo-container.float {
  text-align: center;
  margin-bottom: 1em;
}

.logo-container.float > img.logo-icon-image {
    height: 2.5em;
    margin-right: 0.25em;
    vertical-align: middle;
}

.logo-container.float > img.logo-text-image {
    vertical-align: middle;
    margin-top: 0.2em;
}

.content-container.float {
  background-color: #fff;
  padding: 5px 0 50px;
  max-width: 600px;
  margin: 0 auto;
}

.sub-container.float {
  text-align: center;
  margin-top: 1em;
  cursor: default;
  font-weight: 300;
}

.sub-container.float * a {
  cursor: pointer;
}

/** FILL LAYOUT **/
@media (min-width: 768px){
  .content-container.fill{
    padding: 2em 3em;
    margin: auto;
    min-height: 100%;
  }
}
@media (max-width: 768px){
  .content-container.fill{
    min-height: 100%;
  }
}

/** FAKEMODAL LAYOUT **/
@media all and (min-width: 480px) {
  .layout-content.fakemodal {
    padding: 60px 0;
  }
}

.logo-container.fakemodal {
  text-align: center;
  margin-bottom: 1em;
}

.content-container.fakemodal {
  background-color: #fff;
  max-width: 600px;
  margin: 0 auto;
}
.content-header.fakemodal{
  background-color: #4AB6AC;
  color: #FFFFFF;
  font-size: 1.5em;
  font-weight: 300;
  padding: 0.5em 1em;
}
.content-header.fakemodal ~ div {
  border: 1px solid #d0d1d2;
  border-top: none;
}

/** Sliding Pane Layout **/
.layout-container.sliding-pane-container{
  position: fixed;
  left: 0;
  top: 0;
  transform: translate3d(-50vw, 0, 0);

  transition: transform .5s cubic-bezier(0, .52, 0, 1);

  background-color: #fff;
  border-right: 2px solid var(--primary-color);

  width: 50vw;
  height: 100vh;
}
.layout-container.sliding-pane-container.active{
  transform: translate3d(0vw, 0, 0);
}
.layout-container.sliding-pane-container.right{
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(150vw, 0, 0);

  transition: transform .5s cubic-bezier(0, .52, 0, 1);

  background-color: #fff;
  border-left: 2px solid var(--primary-color);
  border-right: 0px solid var(--primary-color);

  width: 50vw;
  height: 100vh;
}
.layout-container.sliding-pane-container.right.active{
  transform: translate3d(50vw, 0, 0);
}

@media (max-width: 768px){
  .layout-container.sliding-pane-container{
    transform: translate3d(-100vw, 0, 0);
        
    width: 100vw;
  }
  .layout-container.sliding-pane-container.active{
    transform: translate3d(0vw, 0, 0);
  }
  .layout-container.sliding-pane-container.right{
    transform: translate3d(200vw, 0, 0);
        
    width: 100vw;
  }
  .layout-container.sliding-pane-container.right.active{
    transform: translate3d(0vw, 0, 0);
  }
}

.layout-cover.slidingpane.active{
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #d0d1d2;
  opacity: 0.5;
}

.layout-container.sliding-pane-container .content-container.slidingpane {
  max-height: 100vh;
  overflow: auto;
  padding-bottom: 1em;
}

.layout-container.sliding-pane-container .content-container.slidingpane .slidingpane-close{
  float: right;
  padding-right: 0.25em;
  font-size: 1.4em;
  cursor: pointer;
  opacity: 0.3;
}

.layout-container.sliding-pane-container.right .content-container.slidingpane .slidingpane-close{
  float: left;
  padding-right: 0.25em;
  font-size: 1.4em;
  cursor: pointer;
  opacity: 0.3;
}

.content-container.slidingpane .slidingpane-close:hover {
  opacity: 0.6;
}

.login-content .content-container {
  background-color: #fff;
  border: 0px solid #d6d6d6;
  padding: 2em 2em;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 20px 0 rgba(18, 20, 24, 0.5);
  border-radius: 1em;
  width: 85%;
}
.login-pat-text.form-group{
  font-size: 0.8em;
  text-align: center;
  opacity: 0.6;
  margin-bottom: 0.5em;
  cursor: default;
}
.login-pat-text.form-group * a {
  cursor: pointer;
}
.login-submit-btn{
  font-size: 1.5em;
}
.login-subcontainer {
  text-align: center;
  margin-top: 1em;
  cursor: default;
}
.login-subcontainer * a {
  cursor: pointer;
}
.loginButton{
  margin-top: 1.5em;
}
.login-content .password-eye-icon {
  float: right;
  margin-right: 0.25em;
  margin-top: -1.5em;
  position: relative;
  z-index: 2;
  color: #9e9e9e;
}
.login-content .login-lang-select{
  text-align: center;
  margin-top: 1em;
  margin-bottom: -1em;
}

.login-content .sub-container {
  color: #FFF;
}

.login-content .sub-container a {
  color: #fff;
  text-decoration: underline;
}

.login-content .sub-container a:hover {
  color: #babcc0;
}


.password-criteria-row > div {
  display: inline-block;
}
.password-criteria-row > .password-criteria-row-check{
  width: 1.5em;
}
.password-criteria-row > .password-criteria-row-check .fa-check-circle {
  color: var(--primary-color);
  opacity: 1;
}

.password-criteria-row > .password-criteria-row-check .fa-circle {
  opacity: 0.5;
}

.password-criteria-row > .password-criteria-row-text{
  font-weight: 300;
  width: 94%;
}

@media ( max-width: 991px ) {
  .password-criteria-row {
    font-size: 0.75em
  }
}

#validSpecChars {
  font-weight: lighter;
  font-size: 0.75em;
  padding-left: 2%;
}
.resetpassword-container form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.resetpassword-container h1 {
  font-size: 1.25em;
  font-weight : 300;
  text-align: left;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
  cursor: default;
}

.resetpassword-container h2 {
  font-size: 1em;
  font-weight : 300;
  text-align: left;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
  cursor: default;
}

.resetpassword-container h3 {
  font-size: 1.25em;
  font-weight : 300;
  text-align: center;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
  cursor: default;
}

.resetpassword-container .reset-email-input{
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.resetpassword-container .reset-submit-btn {
  font-size: 1.125em;
  padding: 0.5em 0.875em;
}

.resetpassword-container .reset-check-img-container{
  max-width: 450px;
  text-align: center;
  margin: 2em auto 0.5em;
}

.resetpassword-container img.reset-check-image {
    width: 200px;
}
.resetpassword-container .password-eye-icon {
  float: right;
  margin-right: 0.25em;
  margin-top: -1.5em;
  position: relative;
  z-index: 2;
  color: #9e9e9e;
}
.resetpassword-container .password-criteria-container{
  margin-bottom: 1em;
}
.resetpassword-container .resetpassword-lang-select{
  text-align: center;
  margin-top: 1em;
  margin-bottom: -2em;
}

.resetpassword-container .sub-container.box {
  color: #fff;
}

.resetpassword-container .sub-container a {
  color: #fff;
  text-decoration: underline;
}

.resetpassword-container .sub-container a:hover {
  color: #babcc0;
}
@media all and (min-width: 480px) {
  .sq {
    padding: 60px 0;
  }

  .sq form {
    margin: 0 auto;
    max-width: 450px;
  }
}

.sq form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.sq-logo-container {
  text-align: center;
  margin-bottom: 1em;
}

img.logo-icon-image {
    height: 2.5em;
    margin-right: 0.25em;
    vertical-align: middle;
}

img.logo-text-image {
    vertical-align: middle;
    margin-top: 0.2em;
}

.sq-container {
  margin: 0 auto;
}
.sq-container > h1 {
  font-size: 1.5em;
  font-weight : 300;
  text-align: center;
  margin-bottom: 1em;
  cursor: default;
}
.sq-form > .form-group {
  margin-bottom: 1.5em;
}

.half-form-input {
  max-width: 200px;
  display: inline-block;
}
.half-form-input:nth-of-type(1) {
  margin-right:50px;
}
.sq-pat-text.form-group{
  font-size: 0.8em;
  text-align: center;
  opacity: 0.6;
  margin-bottom: 0.5em;
  cursor: default;
}
.sq-pat-text.form-group * a {
  cursor: pointer;
}
.sq-submit-btn{
  font-size: 1.5em;
}
.device-token-content > h1 {
    font-size: 1.4em;
    font-weight : 300;
    text-align: center;
    margin-bottom: 1em;
    cursor: default;
    padding-left: 8%;
    padding-right: 8%;
}

.dt-form > .form-group {
    margin-bottom: 1.5em;
}

.device-token-content .dt-submit-btn{
    font-size: 1.5em;
}
.activate-content-container {
    margin: 0 0.75em;
}
.activate-content-container .activate-content-error-container {
    text-align: center;
    margin: 1.25em 0;
    font-size: 1.1em;
    color: #e53935;
}

.activate-content-container .activate-content-password-container > h3,
.activate-content-container .activate-content-verify-container > h3 {
    font-size: 1.25em;
    font-weight : 300;
    text-align: center;
    margin-bottom: 1em;
    cursor: default;
}

.activate-content-container .activate-content-verify-container > h3 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.activate-content-container .password-eye-icon {
    cursor: pointer;
}

.activate-content-container .activate-content-verify-form .form-group {
    margin-bottom: 2em;
}

.activate-content-container .activate-content-password-criteria {
    margin: 1em 0;
}

.activate-content-container .activate-content-terms {
    height: 400px;
    overflow-y: scroll;
    padding: 0.5em;
    font-size: 0.85em;
    border-style: inset;
}

.activate-content-container .activate-content-terms-actions {
    text-align: center;
}

.activate-content-container .activate-content-terms-actions .activate-content-terms-agree {
    margin-bottom: 2em;
}

.activate-content-container .activate-content-terms-actions .activate-content-terms-btns {
    width: 80%;
    margin: 0 auto;
}

.activate-content-container .activate-content-lang-container {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.activate-content-container .activate-content-lang-container .activate-content-title {
    text-align: center;
    font-size: 1.135em;
    padding: 0.25em 0;
}

.activate-content-container .activate-content-lang-container .activate-content-lang-select-btn {
    width: 60%;
    padding: 1em;
    font-size: 1.35em;
    font-weight: 300;
    border: 1px solid #d0d1d2;
    margin: 0.5em auto;
    text-align: center;
}

.activate-content-container .activate-content-lang-container .activate-content-lang-select-btn:hover {
    background-color: #efefef;
    cursor: pointer;
}

.activate-content-redirect-login-link {
    text-align: center;
}

.activate-content-redirect-text {
    text-align: center;
    font-size: 0.9em;
    padding-bottom: 1.5em;
}

.selectaccount-content .selectaccount-accounts {
    width: 70%;
    margin: 1.5em auto 1em;
    cursor: pointer;
}

.selectaccount-content .selectaccount-accounts .selectaccount-accounts-item:hover {
    background-color: #f1f1f1;

}

.selectaccount-content .selectaccount-accounts .selectaccount-accounts-item {
    outline: none !important;
}

.selectaccount-content .selectaccount-accounts .selectaccount-accounts-item *{
    outline: none !important;
}

.selectaccount-content .selectaccount-accounts-item .selectaccount-accounts-item-asset-container .selectaccount-accounts-item-asset-value {
    color: var(--primary-color);
    font-size: 1.1em;
}

.selectaccount-content .selectaccount-accounts-item .selectaccount-accounts-item-asset-container .selectaccount-accounts-item-asset-asof {
    opacity: 0.5;
    font-size: 0.8135em;
}
/** Page Container **/
  .dashboard-container * .dashboard-grid {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 260px 1fr;
    grid-template-rows: auto 1fr;
    margin: 0 auto;
    border: none;
  }
  
  .dashboard-container * .dashboard-title {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .dashboard-container * .dashboard-sidebar-select-account-container {
    display: grid;
    grid-template-rows: auto auto;
  }

  /** Sidebar **/
  .dashboard-container * .dashboard-sidebar-select-account select.form-control {
    color: var(--primary-color);
    background: var(--select-down-svg) 100% 70% no-repeat;
    background-size: auto 21.75%;
    padding-left: 0.2em;
    padding-right: 0.2em;
    border-bottom-width: 1px;
  }
  .dashboard-container * .dashboard-sidebar-select-timewindow select {
    color: var(--primary-color)
  }

  .dashboard-container * .dashboard-sidebar-select-account-container {
    min-height: 6.2em;
    margin-bottom: 1.5em;
  }

  
  .dashboard-container .dashboard-sidebar-nav-accounts-loading {
    font-weight: 300;
    padding-top: 0.5em;
  }
  .dashboard-container .dashboard-sidebar-nav-accounts-loading svg{
    color: var(--primary-color);
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container {
    display: grid;
    grid-template-columns: 1fr 1em 1fr;
    margin-bottom: 0.75em;
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    display: none;
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    padding: 0.0825em 0 0.165em;
  }
  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container .react-datepicker__month-select, .dashboard-container .dashboard-sidebar .dashboard-date-picker-container .react-datepicker__year-select {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--text-underline);
    background: var(--select-down-svg) 100% 60% no-repeat;
    background-size: auto 30%;
    outline: none !important;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 1em;
  }

  .dashboard-container .dashboard-sidebar .react-datepicker__input-container {
    margin: 0 auto;
    width: 100%;
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-input {
    display: grid;
    grid-template-columns: auto auto;
    padding-left: 0.2em;
    padding-bottom: 0.1em;
    border-bottom: 1px solid var(--text-underline);
    color: var(--primary-color);
    font-size: 0.9em;
    cursor: pointer;
  }
  .dashboard-container .dashboard-sidebar .dashboard-date-picker-input.dashboard-date-picker.disabled{
    color: #333;
    border-bottom-color: #eaeaea;
    cursor: default;
    opacity: 0.5;
  }

  .dashboard-container .dashboard-sidebar .dashboard-date-picker-input .dashboard-date-picker-input-arrow{
    text-align: right;
    font-size: 1.1em;
  }


  .dashboard-sidebar-select-timespan-container{
    display: grid;
    grid-template-columns: repeat(4, auto);
    color: var(--text-secondary);
    font-size: 0.8em;
    margin-bottom: 2em;
    text-align: center;
  }
  .dashboard-sidebar-select-timespan-container.fr{
    display: grid;
    grid-template-columns: repeat(4, auto);
    color: var(--text-secondary);
    font-size: 0.8em;
    margin-bottom: 2em;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item {
    cursor: default;
    padding: 0 0.5em;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item:first-child {
    padding-left: 0;
    text-align: left;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item:nth-child(4) {
    text-align: center;
  }
  .dashboard-sidebar-select-timespan-container.fr .dashboard-sidebar-select-timespan-item:nth-child(4) {
    text-align: right;
    padding-right: 0;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item:last-child {
    text-align: right;
    padding-right: 0;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item span {
    cursor: pointer;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item.disabled span {
    color: #333;
    cursor: default;
    opacity: 0.5;
  }

  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item:last-child span {
    cursor: default;
  }

  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item.active span{
    color: var(--primary-color);
    border-bottom: 1px solid var(--text-underline);
    cursor: default;
  }
  .dashboard-sidebar-select-timespan-container .dashboard-sidebar-select-timespan-item.active.disabled span{
    color: #333;
    border-bottom-color: #eaeaea;
    cursor: default;
    opacity: 0.5;
  }
  /* Sidebar Stats */
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item {
    margin-bottom: 2em;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-header {
    color: var(--primary-color);
    font-size: 1.125em;
    font-weight: 500;
    cursor: default;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center; 
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-header .dashboard-sidebar-stats-item-header-help {
    font-size: 0.7em;
    margin-top: 0.15em;
    color: var(--text-secondary);
    text-align: right;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-header .dashboard-sidebar-stats-item-header-help svg:hover{
    cursor: pointer;
    color: var(--primary-color);
  }
  .dashboard-stats-help-box {
    font-size: 0.8em;
  }
  .dashboard-stats-help-box .popover-content {
    padding: 0.5em 0.75em;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-value {
    font-size: 1.5em;
    padding: 0.25em 0.5em;
    background-color: #FFF;
    box-shadow: 0px 1px 0px 0px #eaeaea;
    cursor: default;
  }
  .dashboard-sidebar-stats .small-symbol {
    font-size: 0.75em;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-fee-values {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
  }
  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-fee-value {
    font-size: 1.5em;
    padding: 0.25em 0.5em;
    background-color: #FFF;
    box-shadow: 0px 1px 0px 0px #eaeaea;
  }

  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-fee-value:first-of-type {
    margin-right: 2em;
  }

  .dashboard-sidebar-stats .dashboard-sidebar-stats-item-subtext {
    font-size: 0.75em;
    padding-top: 0.25em;
    color: var(--text-secondary);
    cursor: default;
  }
  
  /** Dashboard Content **/
  .dashboard-container .dashboard-content-container {
    padding-left: 5.65em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container{
    margin-bottom: 3em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .dashboard-content-gow-header {
    color: var(--primary-color);
    font-size: 1.125em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .growthofwealthchart-chart-container.gow-chart {
    background-color: #FFF;
    padding-left: 2em;
    padding-right: 4em;
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100%;
    height: 400px;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    position: relative;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .growthofwealthchart-chart-container.gow-chart .gow-tooltip {
    position: absolute;
    background-color: #fff;
    border: 1px solid var(--text-secondary);
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-header{
    padding: 0.2em;
    text-align: center;
    font-size: 0.7em;
    background-color:#f9f8f7;
    color: var(--text-secondary)
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content {
    font-size: 0.8em;
    padding: 0.25em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-value-item {
    display: grid;
    grid-template-columns: 1em auto auto;
    align-items: baseline;
    font-size: 0.7em; 
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-value-item .legend-rect {
    height: 0.6em;
    margin: 0.1em 0.3em 0.3em 0.1em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-value-item > span {
    display: inline-block;
    font-size: 1.3em;
    text-align: right;
    padding-left: 1em
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-managed .legend-rect{
    background-color: #c5d368;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-total {
    border-top: 1px solid #dfdfdf;
    margin-top: 0.2em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-total .legend-rect{
    background-color: #aaa;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-nonmanaged .legend-rect{
    background-color: #aaa;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-contributions {
    font-size: 0.7em;
    margin-top: 0.8em;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-contributions-title .legend-rect {
    display: inline-block;
    width: 0.6em;
    height: 0.25em;
    margin: 0.15em 0.2em 0.2em 0.2em;
    background-color: var(--primary-color)
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .gow-tooltip .gow-tooltip-content .gow-tooltip-content-contributions-value {
    text-align: right;
    margin-left: 1em;
    font-size: 1.3em;
    margin-bottom: 0.25em;
  }
  .dashboard-content-pfsummary-content-container {
    display: grid;
    grid-template-columns: 250px 1fr;
  }
  .dashboard-content-pfsummary-chart .component-loading-placeholder .component-loading-text{
    font-size: 1em;
  }
  .dashboard-content-pfsummary-container .dashboard-content-pfsummary-header {
    color: var(--primary-color);
    font-size: 1.125em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .dashboard-content-pfsummary-container .dashboard-content-pfsummary-header span {
    font-size: 0.8em;
    color: var(--text-secondary);
  }
  .dashboard-content-pfsummary-content-container {
    background-color: #FFF;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .dashboard-content-pfsummary-chart-header { 
    text-align: center;
    color: var(--text-secondary);
    width: 100%;
    padding-top: 0.25em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .dashboard-content-pfsummary-chart-container {
    width: 250px;
    height: 250px;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .recharts-wrapper{
    transform: translatey(-20px);
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip {
    transform: translate(-50%, -20px);
    white-space: nowrap;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .small-symbol {
    font-size: 0.8em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .label-pct .small-symbol{
    font-size: 0.6em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .label-pct{
    font-size: 1.25em;
    margin-bottom: 0.05em;
    text-align: center;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .label-pct .label-pct-sm {
    font-size: 0.8em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip .label-cat{
    font-size: 0.85em;
    margin-bottom: 0.15em;
    text-transform: capitalize;
    text-align: center;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table {
    padding-left: 3em;
    white-space: nowrap;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .dashboard-content-pfsummary-table-header {
    display: grid;
    grid-template-columns: 1.75em 1.2fr 0.8fr 0.8fr 0.01fr;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .dashboard-content-pfsummary-table-header-item {
    font-size: 0.9em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: var(--text-secondary);
    text-align: center;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .dashboard-content-pfsummary-table-header-item.title-item {
    text-align: left;
  }

  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .dashboard-content-pfsummary-table-row {
    display: grid;
    grid-template-columns: 1.75em 1.2fr 0.8fr 0.8fr 0.01fr;
    align-items: center;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table .small-symbol{
    font-size: 0.8em;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-row .dashboard-content-pfsummary-table-legend-box {
    height: 1.125em;
    width: 1.125em;
    border-radius: 50%;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-row .dashboard-content-pfsummary-table-row-item {
    text-align: center;
    padding: 0.5em 0;
    border-top: 1px solid #dfdfdf;
    font-weight: 300;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-row .dashboard-content-pfsummary-table-row-item.title-item {
    text-transform: capitalize;
    text-align: left;
    font-weight: 300;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-row .dashboard-content-pfsummary-table-row-item.box-item {
    border-top: none;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-total {
    display: grid;
    grid-template-columns: 1.75em 1.2fr 0.8fr 0.8fr 0.01fr;
    align-items: center;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-total .dashboard-content-pfsummary-table-total-item {
    text-align: center;
    padding: 0.5em 0;
    border-top: 1px solid var(--text-secondary);
    color: var(--primary-color);
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-total .dashboard-content-pfsummary-table-total-item.title-item {
    text-align: left;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table-total .dashboard-content-pfsummary-table-total-item.box-item {
    border-top: none;
  }

 @media ( max-width: 1280px ) { 
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-table {
    font-size: 0.8em;
  }
 }
 @media ( max-width: 1024px ) {  
  .dashboard-container .dashboard-content-container {
    padding-left: 2em;
  }
  .dashboard-content-pfsummary-content-container{
    grid-template-columns: 200px 1fr;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .dashboard-content-pfsummary-chart-container {
    width: 200px;
    height: 250px;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .recharts-wrapper{
    transform: translatey(-0px);
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .custom-radialbar-tooltip {
    transform: translate(-50%, 0px);
  }
}
@media ( max-width: 991px ) { 
  .dashboard-container * .dashboard-grid {
    display: grid;
    grid-template-columns: 260px 1fr;
    grid-template-rows: auto 1fr;
    border: none;
  }
  .dashboard-container * .dashboard-sidebar {
    margin-left: 0em;
    position: relative;
  }
  .dashboard-summary-container {
    margin-left: 0em;
  }
  .dashboard-container .dashboard-sidebar{
    max-width: 260px;
  }
  .dashboard-container * .dashboard-sidebar-select-account-container {
    display: grid;
    grid-template-rows: auto auto;
    max-width: 260px;
  }
}
@media ( max-width: 768px ) {
  .dashboard-container {
    font-size: 0.8em;
  }
  .dashboard-container * .dashboard-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    border: none;
    min-width: 300px;
  }
    
  .dashboard-container .dashboard-grid {
    padding-left: 0;
    padding-right: 0;
  }
  .dashboard-container .dashboard-sidebar {
     width: 100%;
     max-width: 100%;
  }

  .dashboard-container .dashboard-sidebar .dashboard-sidebar-select-account-container {
    grid-template-columns: 260px auto;
    max-width: 100%;
    margin-bottom: 1em;
  }
  .dashboard-container .dashboard-sidebar .dashboard-date-picker-container {
    font-size: 1.2em;
    grid-column-start: 1;
  }
  .dashboard-container .dashboard-sidebar .dashboard-sidebar-select-timespan-container {
    grid-column-start: 2;
    padding-left: 2.5em;
    padding-top: 0.4em;
    font-size: 1em;
    max-width: 325px;
  }
  .dashboard-container .dashboard-sidebar .dashboard-sidebar-stats{
    display: grid;
    grid-template-columns: calc(260px + 1em) 1fr 0.5fr;
  }
  .dashboard-container .dashboard-sidebar .dashboard-sidebar-stats-item {
    padding-right: 1em;
  }
  .dashboard-container .dashboard-sidebar .dashboard-sidebar-stats-item:last-child {
    padding-right: 0em;
  }

  .dashboard-container .dashboard-content-container {
    padding-left: 2%;
  }
  .dashboard-container .dashboard-content-container .dashbaord-content-gow-container .growthofwealthchart-chart-container.gow-chart {
    height: 300px;
  }
  .dashboard-container .dashboard-content-pfsummary-content-container{
    grid-template-columns: 1fr;
  }
  .dashboard-content-pfsummary-content-container .dashboard-content-pfsummary-chart .dashboard-content-pfsummary-chart-container {
    margin: auto;
  }
}

/** Page Container **/
  .portfolios-container * .portfolios-grid {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 260px 1fr;
    margin: 0 auto;
    border: none;
  }

  .portfolios-container * .portfolios-title {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .portfolios-container * .portfolios-sidebar-select-account-container {
    display: grid;
    grid-template-rows: auto auto;
  }

  @media ( max-width: 991px ) {
    .portfolios-container * .portfolios-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      border: none;
    }
    .portfolios-container * .portfolios-sidebar {
      margin-left: 0em;
      position: relative;
    }
    .portfolios-summary-container {
      margin-left: 0em;
    }

  }
  
/** Sidebar **/
  .portfolios-container * .portfolios-sidebar-select-account select.form-control {
    color: var(--primary-color);
    background: var(--select-down-svg) 100% 70% no-repeat;
    background-size: auto 21.75%;
    padding-left: 0.2em;
    padding-right: 0.2em;
    border-bottom-width: 1px;
  }

  .portfolios-container * .portfolios-sidebar-select-account-container {
    min-height: 6.2em;
    margin-bottom: 1.5em;
    max-width: 260px;
  }

  .portfolios-container .portfolios-sidebar-nav-accounts-loading {
    font-weight: 300;
    padding-top: 0.5em;
  }
  .portfolios-container .portfolios-sidebar-nav-accounts-loading svg{
    color: var(--primary-color);
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 0.75em;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    display: none;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    padding: 0.0825em 0 0.165em;
  }
  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container .react-datepicker__month-select, .portfolios-container .portfolios-sidebar .portfolios-date-picker-container .react-datepicker__year-select {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--text-underline);
    background: var(--select-down-svg) 100% 60% no-repeat;
    background-size: auto 30%;
    outline: none !important;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 1em;
  }

  .portfolios-container .portfolios-sidebar .react-datepicker__input-container {
    margin: 0 auto;
    width: 100%;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-input {
    display: grid;
    grid-template-columns: auto auto;
    padding-left: 0.2em;
    padding-bottom: 0.1em;
    border-bottom: 1px solid var(--text-underline);
    color: var(--primary-color);
    font-size: 0.9em;
    cursor: pointer;
  }
  .portfolios-container .portfolios-sidebar .portfolios-date-picker-input.portfolios-date-picker.disabled{
    color: #333;
    border-bottom-color: #eaeaea;
    cursor: default;
    opacity: 0.5;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-input .portfolios-date-picker-input-arrow{
    text-align: right;
    font-size: 1.1em;
  }
  @media ( max-width: 991px ) {
    .portfolios-container * .portfolios-sidebar-select-account-container {
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  /** Portfolio Content **/
  .portfolios-content {
    padding: 0 0em 0 5.75em;
  }

  .portfolios-content .portfolios-content-account-header {
    color: var(--text-secondary);
    font-size: 1em;
    font-weight: 500;
    display: inline-block;
    padding-left: 0.5em;
  }

  .portfolios-content .portfolios-content-summary-container .portoflios-content-summary-header{
    color: var(--primary-color);
    font-size: 1.25em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .portfolios-content .portfolios-content-summary-container .component-loading-placeholder {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .portfolios-content .portfolios-content-summary-container .component-loading-placeholder .component-loading-text {
    font-size: 1em;
  }


  
  .portfolios-content .portfolios-content-summary-container .dashboard-content-pfsummary-content-container {
    background-color: #FFF;
    padding: 1em 3em 2em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 4em;
  }

  .portfolios-content .portfolios-content-summary-container .portfolios-content-summary-content .recharts-wrapper:first-child {
    margin-bottom: -120px;
    margin-right: 190px;
  }
  .portfolios-content .portfolios-content-summary-container .portfolios-content-summary-content .recharts-wrapper:first-child .recharts-pie-sector path{
    stroke-width: 1px;
    stroke: #FFF;
  }
  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .recharts-bar-rectangles.recharts-layer {
    stroke-width: 1px;
    stroke: #FFF;
    shape-rendering:crispEdges;
  }

  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-top {
    text-anchor: middle;
    transform: translate(50%, 0.25em);
  }

  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-top tspan{
    shape-rendering:crispEdges;
    font-weight: 300;
  }

  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-top tspan.small-symbol {
    font-size: 0.8em;
  }

  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-bottom {
    text-anchor: middle;
    transform: translate(50%, 97%);
  }
  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-bottom tspan{
    font-size: 1.125em;
    font-weight: 300;
  }
  .portfolios-content .portfolios-content-summary-container .portfolio-bar-breakdown .portfolio-bar-breakdown-label-bottom tspan.small-symbol{
    font-size: 0.75em;
  }
  /* .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip{
    width: 300px;
    transform: translate(-50%, -2em);
    text-align: center;
  }
  .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip .small-symbol {
    font-size: 0.8em;
  }
  .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip .label-pct .small-symbol{
    font-size: 0.6em;
  }
  .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip .label-pct{
    font-size: 1.5em;
    margin-bottom: 0.15em;
  }
  .portfolios-content .portfolios-content-summary-container .recharts-tooltip-wrapper .custom-radialbar-tooltip .label-cat{
    font-size: 0.85em;
    margin-bottom: 0.15em;
    text-transform: capitalize;
  } */

  .portfolios-content .portfolios-content-summary-container .portfolios-content-summary-content .recharts-wrapper .recharts-legend-item-text {
    vertical-align: middle;
    text-transform: capitalize;
  }



  .portfolios-content .portfolios-content-details-container .portoflios-content-details-header{
    color: var(--primary-color);
    font-size: 1.25em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-content {
    background-color: #FFF;
    padding: 1em 3em 3em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 4em;
    cursor: default;
  }

  .portfolios-content .portfolios-content-details-container .component-loading-placeholder .component-loading-text {
    font-size: 1em;
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-portfolios-container {
    display: grid;
    grid-template-columns: 0.4fr 0.5fr 0.5fr 0.8fr 0.5fr 0.8fr;
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-portfolios-header-item {
    padding: 0.5em 0.25em;
    color: var(--text-secondary);
    border-bottom: 1px solid #aaa;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-header-item.pct-item {
    text-align: center;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item {
    padding: 0.75em 0.25em;

    border-bottom: 1px solid #dfdfdf;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item .small-symbol {
    font-size: 0.8em;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item.account-item {
    font-weight: 300;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item.type-item {
    font-weight: 300;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item.curr-item {
    font-weight: 300;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item.pct-item {
    font-weight: 300;
    text-align: center;
  }

  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(1),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(2),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(3),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(4),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(5),
  .portfolios-content .portfolios-content-details-portfolios-container .portfolios-content-details-portfolios-item:nth-last-child(6){
    border-bottom: none;
  }

  .portfolios-content .portfolios-content-holdings-container {
    cursor: default;
  }

  .portfolios-content .portfolios-content-holdings-container .component-loading-placeholder .component-loading-text {
    font-size: 1em;
  }
  .portfolios-content .portfolios-content-holdings-container .portoflios-content-holdings-header{
    color: var(--primary-color);
    font-size: 1.25em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-content {
    background-color: #FFF;
    padding: 1em 3em 3em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 4em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-container {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-header-item {
    padding: 0.5em 0.25em;
    color: var(--text-secondary);
    border-bottom: 1px solid #aaa;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-header-item.pct-item { 
    text-align: center;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-cat-header {
    color: var(--primary-color);
    padding: 0.75em 0.15em 0.25em;
    font-size: 1.25em;
    font-weight: 700;
    grid-column-start: 1;
    grid-column-end: -1;
  } 

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-subcat-header {
    padding: 0 0.25em 0.25em;
    color: var(--primary-color);
    grid-column-start: 1;
    grid-column-end: -1;
  } 

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item{
    padding: 0.35em 0.25em;
    color: var(--text-secondary);

    border-bottom: 1px solid #dfdfdf;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item .small-symbol {
    font-size: 0.8em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item.name-item{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item.pct-item {
    text-align: center;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-item.ann-item {
    text-align: center;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-total-item { 
    padding: 0.5em 0.25em 1em;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-total-item .small-symbol {
    font-size: 0.8em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-total-item.pct-item {
    text-align: center;
  }
  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-total-item { 
    color: var(--primary-color);
  }

  @media ( max-width: 1280px ) { 
    .portfolios-container .portfolios-grid {
      grid-template-columns: 1fr;
    }
    .portfolios-content {
      padding: 0 0em 0 0em;
      font-size: 0.8em;
    }
    .portfolios-container * .portfolios-sidebar-select-account-container {
      grid-template-columns: 260px 1fr;
      max-width: 100%;
      min-height: 0;
    }
    .portfolios-container .portfolios-date-picker-container {
      padding-left: 2em;
      max-width: 175px;
      font-size: 1.1em;
      padding-top: 0.6em;
    }
    .portfolios-container .portfolios-sidebar .portfolios-date-picker-input {
      font-size: 1em;
    }
    .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-container {
      grid-template-columns: 2fr 1fr 1fr;
    }
  }
@media ( max-width: 768px ) {
  .portfolios-container {
    font-size: 0.8em;
  }
  .portfolios-content {
    font-size: 1em;
  }

  .portfolios-grid .portfolios-content-container .portfolios-content-summary-content {
    grid-template-columns: 425px auto;
  }
  .portfolios-grid .portfolios-content-container .portfolios-content-summary-content .recharts-wrapper:first-child {
    transform: translateX(-40px);
  }
  .portfolios-grid .portfolios-content-container .portfolios-content-summary-content .recharts-legend-wrapper {
    transform: translateX(-40px);
  }
  .pageheader-container {
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-size: 1.2em;
  }
  .pageheader-container .pageheader-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }
  .pageheader-container .pageheader-welcome {
    padding-top: 0;
    grid-template-rows: 1.85em 1.2em;
    padding-bottom: 10%
  }
  .pageheader-container .pageheader-advisor {
    display: none;
  }
  .pageheader-grid .pageheader-balances .pageheader-balance-item {
    grid-template-columns: 1.2fr 1fr;
  }
  .pageheader-grid .pageheader-balances .pageheader-balance-asof {
    font-size: 0.8em;
    grid-template-columns: 2fr;
  }

  .portfolios-container * .portfolios-sidebar-select-account-container, .dashboard-content-pfsummary-content-container  {
    grid-template-columns: 100%;
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-portfolios-container {
    grid-template-columns: 13% 18% 12% 27% 18% 12%;
  }
  
  .portfolios-container * .portfolios-sidebar-select-account select.form-control {
    width: 85%;
    margin-left: 5%;
    padding: 0%;
  }

  .portfolios-container * .portfolios-sidebar-select-account select.form-control option {
    font-size: 0.8em;
  }

  .portfolios-container .portfolios-sidebar .portfolios-date-picker-container {
    width: 85%;
    margin-left: 5%;
    padding: 0%;
    margin-right: 25%;
    max-width: 100%;
    font-size: 1.2em;
  }

  .portoflios-content-summary-header, .portoflios-content-details-header, .portoflios-content-holdings-header {
    text-align: left;
    margin-left: 2%
  }

  .portfolios-content .portfolios-content-details-container .portfolios-content-details-content {
    font-size: 0.65em;
    padding: 1em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-content {
    padding: 1em;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-container {
    grid-template-columns: 60% 25% 15%;
  }

  .portfolios-content .portfolios-content-holdings-container .portfolios-content-holdings-cat-header {
    font-size: 0.85em;
  }

  .navbar-inverse .navbar-nav > li > a {
    font-size: 1.5em;
    text-align: left;
    width: 100%;
    margin: 3.5% 3.5% 2% 3.5%;
  }

  .app-header.navbar-inverse .navbar-lang.dropdown > a, .app-header.navbar-inverse .navbar-lang.dropdown {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  .app-header.navbar-inverse .navbar-lang.dropdown {
    text-align: left;
    width: 100%;
    margin: 3.5% 3.5% 2% 3.5%;
  }

  .navbar-nav .open .dropdown-menu {
    width: 100%;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    font-size: 1.5em;
    width: 100%;
    margin: 2%;
  }
  .navbar-inverse .navbar-nav > .active > a {
    width: auto;
  }
}
/** Page Container **/
.performance-container * .performance-grid {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 260px 1fr;
    grid-template-rows: auto 1fr;
    margin: 0 auto;
    border: none;
  }

/** Sidebar content **/
  .performance-grid .performance-sidebar .performance-sidebar-group-header{
    color: var(--text-secondary);
    font-size: 1.25em;
  }
  .performance-grid .performance-sidebar .performance-sidebar-nav-groups-loading svg{
    color: var(--primary-color);
  }
  .performance-grid .performance-sidebar .performance-sidebar-group-container {
    padding: 0.25em 0;
  }

  .performance-grid .performance-sidebar .performance-sidebar-group-container .performance-sidebar-group-item {
    padding: 0.25em 0;

  }

  .performance-grid .performance-sidebar .performance-sidebar-group-container .performance-sidebar-group-item span{
    cursor: pointer;
    color: var(--primary-color)
  }

  .performance-grid .performance-sidebar .performance-sidebar-group-container .performance-sidebar-group-item span:hover {
    color: var(--primary-color-dark)
  }


/** Perforamnce Content **/  
  .performance-content {
    padding: 0 0em 0 5.75em;
  }
  .performance-content .performance-content-group {
    padding-bottom: 2.5em;
    margin-bottom: 2em;
  }
  .performance-content .performance-content-group:last-child {
    padding-bottom: 2.5em;
    margin-bottom: 0em;
  }
  .performance-content .performance-content-header{
    color: var(--primary-color);
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 0.75em;
    border-bottom: 1px solid #dfdfdf;
  }

  .performance-content .performance-content-group-header {
    color: var(--text-secondary);
    font-size: 1em;
    font-weight: 500;
    display: inline-block;
    padding-left: 0.5em;
  }

  .performance-content .performance-annual-returns-header{
    color: var(--primary-color);
    font-size: 1.1em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .performance-content .performance-annual-returns-header .performance-content-returns-sub-header {
    color: var(--text-secondary);
    font-size: 0.85em;
    font-weight: 500;
    display: inline-block;
    padding-left: 0.5em;
  }

  .performance-content .performance-content-compound-returns-container {
    margin-bottom: 2em;
  }

  .performance-content .performance-annual-returns-content{
    background-color: #FFF;
    padding: 2em 3em 3em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 2em;
    display: grid;
    grid-template-columns: auto; 
    align-items: center;
  }

  .performance-content .performance-annual-returns-content .y-axis-label {
    fill: var(--text-secondary);
    text-anchor: end;
    alignment-baseline: text-before-edge;
    font-size: 0.8em;
    font-weight: 300;
  }

  .performance-content .performance-annual-returns-content .y-axis-line {
      stroke: var(--text-secondary);
      stroke-width: 1;
      shape-rendering: crispEdges;
    }

  .performance-content .performance-annual-returns-content .recharts-reference-line-line {
      shape-rendering: crispEdges;
  }

  .performance-content .performance-annual-returns-content .recharts-rectangle {
      shape-rendering: crispEdges;
  }


  .performance-content .performance-annual-returns-content .yAxis text {
        fill: var(--text-secondary);
        font-weight: 300;
        font-size: 0.85em;
  }

  .performance-content .performance-annual-returns-content .xAxis text {
        fill: var(--text-secondary);
        font-weight: 300;
        font-size: 0.85em;
  }

  .performance-content .performance-annual-returns-content .bar-label-text {
      font-size: 0.7em;
  }

  .performance-content .performance-compound-returns-header{
    color: var(--primary-color);
    font-size: 1.1em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }
  .performance-content .performance-compound-returns-header .performance-content-compound-returns-sub-header {
    color: var(--text-secondary);
    font-size: 0.85em;
    font-weight: 500;
    display: inline-block;
    padding-left: 0.5em;
  }
  .performance-content .performance-compound-returns-content{
    background-color: #FFF;
    padding: 1em 3em 2em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
  }

  .performance-content .performance-compound-returns-headers {
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 0.8fr 1fr;
    color: var(--text-secondary);
    text-align: center;
    padding-bottom: 0.5em;
  }

  .performance-content .performance-compound-returns-headers .performance-compound-returns-header-item.ytd-item {
    text-align: left;
  }

  .performance-content .performance-compound-returns-headers .cp-item {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 0.25em 0;
    font-size: 0.9em;
  }

  .performance-content .performance-compound-returns-items {
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 0.8fr 1fr;
    padding: 0.5em 0;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    text-align: center;
    font-weight: 300;
  }
  .performance-content .performance-compound-returns-items .performance-compound-returns-item .small-symbol{
    font-size: 0.75em;
  }
  .performance-content .performance-compound-returns-items .performance-compound-returns-item:first-child{
    text-align: left;
  }

  .performance-content .performance-portfolios-content{
    background-color: #FFF;
    padding: 1em 3em 1.5em;
    width: 100%;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    margin-bottom: 2em;
  }

  .performance-content .performance-portfolios-header{
    color: var(--primary-color);
    font-size: 1em;
    font-weight: 500;
    padding-bottom: 0.25em;
  }


  .performance-content .performance-portfolios-content .component-loading-placeholder .component-loading-text {
    font-size: 1em;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-header{
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 1fr 0.6fr 0.3fr;
    padding: 0.5em 0.25em;
    color: var(--text-secondary);
    border-bottom: 1px solid #aaa;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-header .curr-item {
    text-align: center;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-item{
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 1fr 0.6fr 0.3fr;
    font-weight: 300;
    border-bottom: 1px solid #dfdfdf;
    padding: 0.5em 0.25em;
  }
  .performance-content .performance-portfolios-content .performance-portfolios-content-item:last-child {
    border-bottom: 0px solid transparent;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-item .name-item { 
    font-weight: 400;
  }

  .performance-content .performance-portfolios-content .performance-portfolios-content-item .curr-item {
    text-align: center;
  }
  .performance-content .performance-group-scroll-to-top {
    margin: 1em 0 0;
  }

  .performance-content .performance-group-scroll-to-top span {
    color: var(--text-secondary);
    opacity: 0.8;
    cursor: pointer;
    font-size: 0.8em;
  }
  .performance-content .performance-group-scroll-to-top span:hover {
    opacity: 1;
  }

  @media ( max-width: 1280px ) { 
    .performance-container * .performance-grid {
      grid-template-columns: 260px 1fr;
      max-width: 100%;
    }
    .performance-content {
      font-size: 0.7em;
      padding-left: 2em;
      max-width: 100%;
    }
    .performance-content .performance-annual-returns-content{
      max-width: 100%;
    }
  }
  @media ( max-width: 767px ) { 
    .performance-container * .performance-grid {
      grid-template-columns: 1fr;
      max-width: calc(767px - 6em);
    }
    .performance-grid .performance-sidebar .performance-sidebar-group-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0.25em 2em 2em;
    }
    .performance-container .performance-content {
      padding-left: 0em;
      max-width: 100%;
    }
    .performance-content .performance-annual-returns-content {
      max-width: 100%;
    }

    .performance-annual-returns-header, .performance-sidebar-group-header, .performance-compound-returns-header, .performance-content-header, .performance-portfolios-header {
      margin-left: 2%;
    }

    .performance-content .performance-group-scroll-to-top {
      font-size: 1.5em;
      margin-left: 3%;
    }

    .performance-content .performance-compound-returns-content, .performance-content .performance-portfolios-content, .performance-content .performance-annual-returns-content {
      padding: 1em;
    }

    .performance-content .performance-content-header {
      font-size: 1.1em;
    }

    .performance-content .performance-portfolios-content .performance-portfolios-content-header, .performance-content .performance-portfolios-content .performance-portfolios-content-item {
      grid-template-columns: 21% 20% 45% 14%;
    }

    .performance-content .performance-compound-returns-items, .performance-content .performance-compound-returns-headers {
      grid-template-columns: 13% 12% 16% 16% 16% 30%;
    }
  }

/** Page Container **/
.documents-container * .documents-grid {
  display: grid;
  max-width: 1600px;
  grid-template-columns: 350px 1fr;
  grid-template-rows: auto 1fr;
  margin: 0 auto;
  border: none;
}

/** Sidebar **/
.documents-container .documents-sidebar {
  padding-right: 3em;
}

.documents-container .documents-sidebar-type-container {
  padding: 2.125em 0 1em;
  width: 100%;
}

.documents-container .documents-sidebar-header {
  color: var(--primary-color);
  font-size: 1.25em;
  font-weight: 500;
  cursor: default;
  padding-bottom: 0.5em;
}

.documents-container .documents-sidebar-type-container .document-type-item {
  color: var(--text-secondary);
  padding: 0.125em 0;
  font-size: 1em;
}

.documents-container
  .documents-sidebar-type-container
  .document-type-item
  span {
  cursor: pointer;
}

.documents-container
  .documents-sidebar-type-container
  .document-type-item.active
  span {
  color: var(--primary-color);
  cursor: default;
}

.documents-container .documents-sidebar-upload-container {
  padding-left: 1.75em;
  padding-top: 0.25em;
}

.documents-container
  .documents-sidebar-upload-container
  .documents-sidebar-upload-btn {
  font-size: 0.85em;
  padding: 0.25em 1em;
  background-color: transparent;
}

/** Page Content **/
.documents-container .documents-content-header {
  color: var(--primary-color);
  font-size: 1.25em;
  font-weight: 500;
  cursor: default;
  padding-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.documents-container
  .documents-content-document-list
  .documents-content-document-header-container {
  background-color: #fff;
  padding: 1em 1em 2em;
  box-shadow: 0px 1px 1px 0px #eaeaea;
  border-radius: 0.3em;
}

.documents-container
  .documents-content-document-list
  .documents-content-document-list-header {
  display: grid;
  grid-template-columns: 5% 27% 27% 27% auto;
  margin: 0 0 0.75em 0.25em;
  border-bottom: 1px solid #aaa;
}

.documents-container
  .documents-content-document-list
  .document-list-header-item {
  padding: 0.5em 0.25em 0.1em;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
}

.documents-container
  .documents-content-document-list
  .document-list-header-item .checkbox {
  padding: 0.5em;
}


.documents-container
  .documents-content-document-list
  .documents-content-document-month-container {
  background-color: #fff;
  padding: 0.5em 1em 2em 0;
  margin-bottom: 1em;
  box-shadow: 0px 1px 1px 0px #eaeaea;
  border-radius: 0.3em;
}

.documents-container .documents-content-document-list .document-list-item {
  display: grid;
  grid-template-columns: 6% 27% 27% 27% auto auto;
  padding: 0 0 0.25em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field {
  padding: 0.75em 0;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field
  .checkbox {
  margin-top: 0;
  padding-left: 1em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .uploaded_date-field {
  opacity: 1;
  cursor: default;
  font-weight: 300;
  border-bottom: 1px solid transparent;
}

.documents-container
  .documents-content-document-list
  .document-list-item:first-child
  .uploaded_date-field {
  opacity: 1;
}

@media (max-width: 915px) {
  .row.layout-header.fill {
    margin-left: 0;
  }
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .display_name-field
  .document-list-item-field-subtitle {
  opacity: 0.5;
  font-size: 0.9em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .status-field
  span {
  vertical-align: middle;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .status-field
  .document-list-item-field-subtitle {
  font-size: 0.9em;
  display: inline-block;
  margin-left: 0.5em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field.download_field
  svg {
  opacity: 0.5;
  cursor: pointer;
  font-size: 1.2em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field.download_field
  svg:hover {
  opacity: 0.75;
  color: var(--primary-color);
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field.delete_field
  svg {
  opacity: 0.25;
  cursor: pointer;
  font-size: 1.2em;
}

.documents-container
  .documents-content-document-list
  .document-list-item
  .document-list-item-field.delete_field
  svg:hover {
  opacity: 0.75;
  color: var(--danger-text);
}

#popover-delete-document {
  min-width: 300px;
}
#popover-delete-document .popover-delete-document-btn-container {
  margin-top: 1em;
  text-align: center;
}

.documents-container
  .documents-content-document-list
  .documents-content-document-no-data {
  padding: 1em 1em;
  opacity: 0.75;
}

/** Modal **/
.document-upload-modal .modal-header {
  background-color: var(--primary-color);
}
.document-upload-modal .modal-title {
  color: var(--header-text);
}

.document-upload-modal .document-upload-input .form-group {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
}

.document-upload-modal .document-upload-input .form-group .sv-input-label {
  color: var(--text-secondary);
}

.document-upload-modal .document-upload-input .form-group .sv-input {
  padding-left: 1em;
  padding-right: 1em;
}
.document-upload-modal .document-upload-input .form-group .help-block {
  margin-top: 0.2em;
  opacity: 0.7;
  font-size: 0.7em;
}

.document-upload-modal
  .document-upload-input
  .document-upload-dropzone-container {
  text-align: center;
  margin: 1.5em 3em 1em;
}

.document-upload-modal .document-upload-input .dropzone-prop-container {
  padding: 2em 0;
  border: 1px dashed var(--text-secondary);
}

.document-upload-modal .document-upload-input .dropzone-prop-container.active {
  border-color: var(--primary-color);
}

.document-upload-modal .document-upload-input .dropzone-prop-container p {
  margin-bottom: 0;
  cursor: default;
}

.document-upload-modal .document-upload-input .dropzone-prop-container-image {
  margin-bottom: 0;
  font-size: 5em;
  color: var(--text-secondary);
  cursor: default;
}

.document-upload-modal
  .document-upload-input
  .dropzone-prop-container-result-image {
  margin-bottom: 0;
  font-size: 5em;
  color: var(--text-secondary);
  cursor: default;
}

.document-upload-modal
  .document-upload-input
  .old-document-upload-dropzone-container {
  text-align: center;
  font-size: 0.85em;
  cursor: default;
}

.document-upload-modal
  .document-upload-input
  .old-document-upload-dropzone-container
  span {
  color: var(--primary-color);
  cursor: pointer;
}

.documents-container .dropdown.btn-group {
  width: 100%;
}

.document-upload-modal .document-modal-btn-container {
  margin: 2em 0 1em;
  text-align: center;
}
.document-upload-modal .document-modal-btn-container button {
  margin: 0 1em;
  font-size: 1.125em;
  padding-left: 1em;
  padding-right: 1em;
}
.document-upload-modal .document-modal-upload-status-container {
  padding: 2em 0;
  border: 1px dashed var(--text-secondary);
  text-align: center;
  color: green;
  margin: 1.5em 3em 1em;
}

.document-upload-modal
  .document-upload-dropzone-container.complete
  .document-modal-upload-status-container {
  padding: 1em 0;
  font-size: 2em;
  margin: 0.75em 1.5em 0.5em;
  border: 1px dashed transparent;
}

.document-upload-modal .document-modal-upload-status-container.error {
  color: red;
}

.document-upload-modal .dropzone-prop-container-accepted span {
  margin: 0.25em 0;
  font-size: 0.8em;
  opacity: 0.8;
  cursor: pointer;
}
.document-upload-modal .dropzone-prop-container-accepted span:hover {
  opacity: 1;
  color: var(--primary-color);
}
#accepted-file-popover {
  max-width: 300px;
}
#accepted-file-popover .popover-content span {
  font-size: 0.8em;
  color: var(--text-secondary);
}

.smallScreen {
  display: none;
}

.largeScreen {
  display: block;
}

.display_name-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1000px) {
  .documents-container * .documents-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    padding-left: 5%;
  }

  .documents-container .documents-sidebar-upload-container {
    padding-left: 0;
  }

  .documents-container .documents-sidebar-type-container .document-type-item {
    color: var(--text-secondary);
    padding: 0.125em 0;
    font-size: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }

  .documents-container .documents-content-document-list .document-list-item {
    display: grid;
    grid-template-columns: 10% 20% 50% 10% 5% 5%;
    padding: 0 0.25em;
    font-size: 0.7em;
  }

  .document-list-item-field {
    margin-right: 0.75em;
  }

  .documents-container
    .documents-content-document-list
    .documents-content-document-month-container {
    background-color: #fff;
    padding: 0.3em;
    margin-bottom: 1em;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
  }

  .documents-container .documents-sidebar {
    padding-right: 3em;
    padding-bottom: 5%;
  }

  .documents-content-document-item-container {
    width: 100%;
  }

  .documents-container
    .documents-content-document-list
    .documents-content-document-list-header {
    margin: 0%;
    width: 100%;
    grid-template-columns: 10% 20% 50% 10% auto auto;
    font-size: 0.9em;
  }

  /* For Mobile Design */
  .smallScreen {
    display: block;
  }

  .largeScreen {
    display: none;
  }

  #documentMobileOption:focus {
    border-bottom-color: var(--text-underline);
  }

  #documentMobileOption {
    border: 0px solid transparent;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 2px solid #e7e7e7;
    line-height: 1;
    padding-bottom: 0px;
    outline: none !important;
    -webkit-appearance: none;
    appearance: none;
    background: var(--select-down-svg) 96% 70% no-repeat;
    background-size: auto 20%;
    cursor: pointer;
    padding: 0% 0% 2% 5%;
    width: 100%;
    text-align: left;
  }

  .dropzone-prop-container-accepted.smallScreen {
    text-align: center;
  }

  .pageheader-container .pageheader-welcome-message,
  .pageheader-grid
    .pageheader-balances
    .pageheader-balance-item
    .pageheader-balance-item-value,
  .documents-container .documents-content-header {
    font-size: 0.85em;
  }

  .pageheader-container .pageheader-balances .pageheader-balance-item-header {
    font-size: 0.7em;
  }

  @media (max-width: 500px) {
    .documents-container .documents-content-document-list .document-list-item {
      grid-template-columns: 10% 28% 28% 23% auto auto;
    }
    .documents-container
      .documents-content-document-list
      .documents-content-document-list-header {
      grid-template-columns: 10% 27% 27% 27% auto;
    }
  }
}

    .accountsettings-container * .accountsettings-grid {
        display: grid;
        max-width: 1600px;
        grid-template-columns: 1fr minmax(100%, 1000px) 1fr;
        grid-template-rows: auto 1fr;
        margin: 0 auto;
        border: none;
    }

    .accountsettings-container .accountsettings-info-container {
        background-color: #FFF;
        padding-left: 1em;
        padding-right: 2em;
        padding-top: 1em;
        padding-bottom: 2em;
        width: 100%;
        max-width: 1000px;
        box-shadow: 0px 1px 1px 0px #eaeaea;
        border-radius: 0.3em;
        display: grid;
        grid-template-columns: 110px auto;
        margin: 0 auto;
    }

    .accountsettings-container .accountsettings-info-container .accountsettings-info-image img{
        border-radius: 50%;
        height: 5em;
        width: 5em;
        object-fit: cover;
        background-color: #eee;
    }

    .accountsettings-container .accountsettings-info-container .accountsettings-info-image svg{
        width: 80%;
        height: 5em;
        color: #E2DEDD;
    }

    .accountsettings-container .accountsettings-info-container .accountsettings-info-image svg.fa-circle-notch{
        color: var(--primary-color);
        opacity: 0.75;
    }

    .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-img {
        margin: 0 auto;
        text-align: center;
    }  
    .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-container {
        margin-top: 1em;
        text-align: center;
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-container .accountsettings-change-avatar-text {
        color: var(--text-secondary);
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-btn {
        font-size: 0.7em;
    }  

    .accountsettings-container .accountsettings-info-container .accountsettings-info-content {
        padding-left: 1em;
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-info-name {
        font-size: 2em;
        font-weight: 300;
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-info-email{
        font-size: 1.25em;
        color: var(--text-secondary);
    }
    .accountsettings-container .accountsettings-info-container .accountsettings-info-notifications {
        margin-top: 2em;
        max-width: 600px;
    }
    .accountsettings-container .accountsettings-info-notifications .accountsettings-notifications-header {
        color: var(--text-secondary);
        border-bottom: 1px solid #dfdfdf;
        padding-right: 1em;
    }
    .accountsettings-container .accountsettings-notifications-mobile {
        display: grid;
        grid-template-columns: auto auto 1fr;
        align-items: center;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-header {
        padding: 0.5em 0 0.25em;
        font-weight: 300;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-toggle-container {
        padding: 0.25em 0.5em 0;
    }  
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle {
        font-size: 1em;
        margin-top: 0.3em;
        color: #aaa;
    }   
    .accountsettings-container .accountsettings-notifications-mobile .react-phone-number-input__icon {
        border: 0px solid transparent;
    }
    .accountsettings-container .accountsettings-notifications-mobile .react-phone-number-input__icon img{
        vertical-align: baseline;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg {
        margin-right: 0.5em;
        font-size: 0.8em;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-check.disabled{
        color: #dfdfdf;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-pencil:hover{
        color: var(--primary-color);
        cursor: pointer;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-check.disabled:hover{
        color: #dfdfdf;
        cursor: default;
        pointer-events: none;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-check:hover{
        color: var(--primary-color);
        cursor: pointer;
    }
    .accountsettings-container .accountsettings-notifications-mobile .accountsettings-notifications-mobile-edit-toggle svg.fa-times:hover{
        color: var(--danger-text);
        cursor: pointer;
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms {
        display: grid;
        grid-template-columns: auto 2.25em 1fr;
        align-items: center;
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms .accountsettingsnotifications-notifications-sms-toggle svg {
        width: 100%;
        height: 1em;
        padding-left: 1.25em;
        cursor: pointer;
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms .accountsettingsnotifications-notifications-sms-toggle .fa-square{
        color: var(--text-secondary);
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms .accountsettingsnotifications-notifications-sms-toggle .fa-check-square{
        color: var(--primary-color);
    }
    .accountsettings-container .accountsettingsnotifications-notifications-sms .accountsettingsnotifications-notifications-sms-message {
        padding: 0.25em 0 0.25em;
        font-weight: 300;
    }
    @media ( max-width: 768px ) {
        .accountsettings-container {
          font-size: 0.8em;
        }
        .accountsettings-container .accountsettings-info-container {
            display: grid;
            grid-template-columns: auto;
        }

        .accountsettings-container .accountsettings-info-container .accountsettings-info-image {
            cursor: pointer;
        }
        .accountsettings-container .accountsettings-info-container .accountsettings-info-image svg {
            height: 10em;
        }

        .accountsettings-container .accountsettings-info-container .accountsettings-change-avatar-btn {
            font-size: 1.3em;
            margin-bottom: 2em;
        }

        .accountsettings-container .accountsettings-info-container .accountsettings-info-content {
            font-size: 0.85em;
        }

        .accountsettings-container .accountsettings-info-container .accountsettings-info-name {
            font-size: 1.5em;
        }
    }
.securitysettings-container * .securitysettings-grid {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 350px 1fr;
    grid-template-rows: auto 1fr;
    margin: 0 auto;
    border: none;
}

.securitysettings-container .securitysettings-security-container {
    background-color: #FFF;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 2em;
    width: 100%;
    max-width: 1000px;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    display: grid;
    grid-template-columns: 100px auto
}
.securitysettings-container .securitysettings-security-container .securitysettings-info-image svg{
    width: 80%;
    height: 5em;
    color: #E2DEDD;
}

.securitysettings-container .securitysettings-security-container .securitysettings-info-name {
    font-size: 2em;
    font-weight: 300;
}
.securitysettings-container .securitysettings-security-container .securitysettings-info-email{
    font-size: 1.25em;
    color: var(--text-secondary);
}
.securitysettings-container .securitysettings-security-container .securitysettings-security-content {
    grid-column-start: 2;
    font-weight: 300;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-items .component-loading-placeholder > .component-loading-text {
    font-size: 1.25em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-security-item {
    margin: 0.5em 0;
}
.securitysettings-container .securitysettings-security-container .securitysettings-security-item span {
    cursor: pointer;
}
.securitysettings-container .securitysettings-security-container .securitysettings-security-item svg{
    color: #aaa;
    margin-right: 0.25em;
    font-size: 0.8em;
}

.securitysettings-container .securitysettings-security-container .securitysettings-security-item span:hover svg {
    color: var(--primary-color);
}

.securitysettings-container .securitysettings-security-container .securitysettings-devices-content {
    grid-column-start: 2;
    margin-top: 1em;
    font-weight: 300;
    max-width: 600px;
    position: relative;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-content .securitysettings-devices-header {
    color: var(--text-secondary);
    border-bottom: 1px solid #aaa;
    padding-right: 1em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-content .securitysettings-devices-item {
    padding: 0.5em 0;
    border-bottom: 1px solid #dfdfdf;
    display: grid;
    grid-template-columns: auto 2em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-content .securitysettings-devices-item:last-child {
    border-bottom: 1px solid transparent;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-row > div{
    display: inline-block;
    margin-bottom: 0.25em;
}
.securitysettings-devices-item-info-row {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info div.securitysettings-devices-item-info-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.2fr;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-location {
    font-size: 1.1em;
    font-weight: 300;
    padding-right: 1em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-time {
    font-size: 0.8em;
    color: var(--text-secondary);
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-os {
    padding-right: 1em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-os svg{
    color: var(--primary-color);
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-os span{
    font-size: 0.9em;
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-browser {
    padding-right: 1em;
    text-align: center;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-browser svg{
    color: var(--primary-color);
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-browser span{
    font-size: 0.9em;
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-type {
    padding-right: 1em;
    text-align: center;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-type svg{
    vertical-align: middle;
    color: var(--primary-color);
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-type span{
    font-size: 0.9em;
    text-transform: capitalize;
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-option-item {
    color: var(--text-secondary);
    text-align: center;
    padding-top: 0.1em;
    font-size: 0.8em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-option-item svg:hover{
    color: var(--danger-text);
    cursor: pointer;
}

#securitysettings-deactivate-popover .revoke-token-btn {
    border-radius: 0;
    max-width: 80%;
    margin: 0 auto;
}
/** Password Modal **/
.update-password-modal .modal-header{
    background-color: var(--primary-color);
}
.update-password-modal .modal-title {
    color: var(--header-text);
}
.update-password-modal .update-password-modal-content {
    width: 85%;
    margin: 0 auto;
}
.update-password-modal .update-password-modal-content .password-eye-icon {
    float: right;
    margin-right: 0.25em;
    margin-top: -1.5em;
    position: relative;
    z-index: 2;
    color: #9e9e9e;
    cursor: pointer;
  }
.update-password-modal .update-password-modal-content .update-password-mfa-text{
    font-size: 0.9em;
    color: var(--text-secondary);
    margin: 2em 0 0.5em;
}
.update-password-modal .update-password-modal-content .update-password-submit-btn {
    max-width: 225px;
    margin: 2.5em auto 0.5em;
}
/** SQ Modal **/
.update-sq-modal .modal-header{
    background-color: var(--primary-color);
}
.update-sq-modal .modal-title {
    color: var(--header-text);
}
.update-sq-modal .update-sq-modal-content {
    width: 85%;
    margin: 0 auto;
}
.update-sq-modal .update-sq-modal-content .control-label {
    font-size: 0.9em;
    color: var(--text-secondary);
}
.update-sq-modal .update-sq-modal-content .update-sq-mfa-text{
    font-size: 0.9em;
    color: var(--text-secondary);
    margin: 3em 0 1em;
}
.update-sq-modal .update-sq-modal-content .update-sq-question-text {
    margin-bottom: 0.75em;
    color: var(--primary-color)
}
.update-sq-modal .update-sq-modal-content .update-sq-submit-btn {
    max-width: 250px;
    margin: 2.5em auto 0.5em;
}

@media ( max-width: 915px ) { 
    .row {
        width: 100%;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-security-content, .securitysettings-container .securitysettings-security-container .securitysettings-devices-content {
        grid-row-start: unset;
        grid-column-start: unset;
    }

    .securitysettings-container * .securitysettings-grid, .securitysettings-container .securitysettings-security-container {
        grid-template-columns: 100%;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-info-name {
        font-size: 1.5em;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-info-email {
        font-size: 0.85em;
    }

    .securitysettings-container .securitysettings-security-container {
        padding-left: 1em;
        padding-right: 1em;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-info-image svg {
        margin-left: 10%;
    }

    .securitysettings-devices-item-info-row {
        font-size: 0.7em;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info div.securitysettings-devices-item-info-group {
        grid-template-columns: 33% 33% 34%;
    }
}
.content-container.notfound {
  padding-top: 100px;
  text-align: center;
}
.notfoundicon { 
  opacity: .2;
}

.App, .app-container {
  padding-top: 51px;
  height: 100%;
  min-height: 100%;
  position: relative;
}

.App .navbar-brand {
  font-weight: bold;
}

.small-symbol {
  font-size: 1em !important;
}
/** Custom Input CSS **/
.form-group span.help-block{
  font-size: 0.85em;
  margin: 0 0 -1em;
}
.form-group input.form-control {
  border: 0px solid transparent;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #e7e7e7;
  line-height: 1;
  padding-bottom: 0;
  outline: none !important;
  box-shadow: none;
}
.form-group.has-error input.form-control {
  border-bottom: 2px solid #a94442;
}

.form-group input.form-control:focus {
  border-bottom-color: var(--primary-color);
  box-shadow: none;
}

.form-group select.form-control {
  border: 0px solid transparent;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #e7e7e7;
  line-height: 1;
  padding-bottom: 0px;
  outline: none !important;
  -webkit-appearance: none;
  appearance: none;
  background: var(--select-down-svg) 96% 70% no-repeat;
  background-size: auto 20%;
  cursor: pointer;
}

.form-group select.form-control:focus {
  border-bottom-color: var(--text-underline);
}

.form-group input.form-control + span.input-group-addon {
  border: 0px solid transparent;
  border-radius: 0;
  box-shadow: none;
  outline: none !important;
  border-bottom: 2px solid #e7e7e7;
  background-color: #FFF;
  color: #9e9e9e;
}

.form-group input.form-control:focus + span.input-group-addon {
  border-bottom-color: var(--primary-color);
  box-shadow: none;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

select::-ms-expand { 
    display: none; /* remove default arrow in IE 10 and 11 */
}

.form-group .control-label {
  font-weight: 400;
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
    select {
        background:none\9;
        padding: 5px\9;
    }
}

/** Placeholder CSS **/
.form-control::placeholder{
  color: #e7e7e7;
}
.form-control::-webkit-input-placeholder { /* Chrome */
  color: #e7e7e7;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #e7e7e7;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #e7e7e7;
  opacity: 1;
}
.form-control:-moz-placeholder { /* Firefox 4 - 18 */
  color: #e7e7e7;
  opacity: 1;
}

/** Custom Button CSS **/
.btn {
  border-radius: 0px;
  outline: none !important;
  border-radius: 1.5em;
}

.btn.btn-default {
  background-color: var(--primary-color);
  color: #FFF;
  border: 1px solid var(--primary-color);
  border-radius: 1.5em;
}

.btn.btn-default:hover {
  background-color: var(--primary-color-dark);
  border: 1px solid var(--primary-color-dark);
}

.btn.btn-inverse {
  background-color: #FFF;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 1.5em;
}

.btn.btn-inverse:hover {
  background-color: #efefef;
  color: var(--primary-color-dark);
  border: 1px solid var(--primary-color-dark);
  border-radius: 1.5em;
}

.btn.btn-inverse-grey {
  background-color: #FFF;
  color: #757575;
  border: 1px solid #a4a4a4;
  border-radius: 1.5em;
}

.btn.btn-inverse-grey:hover {
  background-color: #efefef;
  color: #616161;
  border: 1px solid #8e8e8e;
  border-radius: 1.5em;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: default;
  pointer-events: none;
  background-color: #babcc0;
  border-color:#babcc0;
  color: #5a5a5a;
  opacity: 1;
  border-radius: 1.5em;
}

.btn.btn-red{
  background-color: #ff4c4c;
  color: #FFF;
  border: 1px solid #ff4c4c;
  border-radius: 1.5em;
}

.btn.btn-red:hover{
  background-color: #e04242;
  color: #FFF;
  border: 1px solid #e04242;
  border-radius: 1.5em;
}

.dropdown-menu>.disabled>a:focus, .dropdown-menu>.disabled>a:hover {
  cursor: default;
}
a:focus {
  outline: none
}
/** Loading button default styles **/
button > .loaderBtn.glyphicon {
  margin-right: 0.5em;
  vertical-align: text-top;
  transform: translateY(-100%);
} 

.system-box .alexa-header-icon {
  width: 1.25em;
}

/** Language select dropdown **/
.langselect-container {
  text-align: center;
  margin-top: 1em;
  margin-bottom: -1em;
}
.langselect-container .btn-link:focus, .langselect-container .btn-link:hover, .langselect-container .btn-link{
  color: var(--header-text);
  text-decoration: none;
}
.langselect-container .btn-group.open .dropdown-toggle {
  box-shadow: none;
}
.langselect-container .dropdown-menu {
  min-width: 1.5em;
  border-radius: 0;
}
.langselect-container button{
  border-radius: 0;
  background-color: transparent;
}

/** Loading Placeholder **/
.component-loading-placeholder {
  margin: 2em 0;
}
.component-loading-placeholder > .component-loading-text{
  margin: 0 auto;
  font-size: 2em;
  text-align: center;
  opacity: 0.5;
}
.component-loading-placeholder > .component-loading-text > span {
  display: inline-block;
  width: 1em;
  text-align: left;
}
.table-loading-placeholder > .table-loading-text{
  width: 7em;
  margin: 0 auto;
  font-size: 2em;
  text-align: center;
  opacity: 0.5;
}

/** Default React Tables Styles **/
.ReactTable {
  border: 1px solid #bfbbbb;
  background-color: #fff;
}
.ReactTable * {
  outline: none !important;
}
.ReactTable .rt-table {
  overflow: visible;
}
.ReactTable .rt-thead {
  background-color: #ebebeb;
  border-bottom: 1px solid #bfbbbb;
  font-weight: 600;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ReactTable .rt-tbody{
  overflow: visible;
}
.ReactTable .rt-tbody .rt-tr-group{
  border-bottom: 1px solid #bfbbbb;
}
.ReactTable .rt-tbody .rt-tr-group .rt-td {
  border-right: none;
  padding-right: 1em;
  cursor: default;
}
.ReactTable .-pagination {
  background-color: #ebebeb;
  font-size: 0.8em;
  border-top: none;
  box-shadow: none;
}

.ReactTable .-pagination .-previous {
  padding: 0.5em;
  max-width: 200px;
}

.ReactTable .-pagination .-next {
  padding: 0.5em;
  max-width: 200px;
}
.ReactTable .-pagination button {
  background-color: var(--primary-color);
  color: #FFF;
  border-radius: 0;
}
.ReactTable .-pagination button:not([disabled]):hover{
  background-color: var(--primary-color-dark);
}

/** Context Menu Styling **/
.react-contextmenu.dropdown-menu{
  display: block;
  border-radius: 0;
  z-index: 0;
}
.react-contextmenu.dropdown-menu .react-contextmenu-item{
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.react-contextmenu.dropdown-menu .react-contextmenu-item:hover{
  cursor: pointer;
  background-color: #f5f5f5;
}

/** Submenu support for bootstrap **/
.dropdown-submenu, .dropdown-submenu-left {
  position: relative;
  display: block;
}
@media (min-width: 768px) {
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
  }

  .dropdown-submenu-left .dropdown-menu {
    top: 0;
    right: 100%;
    margin-top: -1px;
  }
}

html, body {
  margin: 0;
  display: table;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  font-size: 14px;
  
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: 16px;
  color: #333;
  overflow-x: hidden;
  background-color: #FFF;
}

#root{
  height: 100%;
  min-height: 100%;
  display: table-cell;
}

/** MobileOverflow Fix for React-Bootstrap **/
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

/** Unselectable styles **/
.unselectable{
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.unselectable * {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/** div cursor default override **/
div {
  cursor: default;
}

/** Animated Spinner Styles **/
.spinning {
  padding-top: 1px;
  animation: spin 1s infinite linear;
}
.spinning.slow {
  animation: spin 1.5s infinite linear;
}
.hover-spin:hover {
  margin-top: -2px;
  padding-top: 2px;
  animation: spin 1s infinite linear;
}
.fa-ease-in-out-spin{
  animation: fa-spin 2s infinite ease-in-out;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

