.selectaccount-content .selectaccount-accounts {
    width: 70%;
    margin: 1.5em auto 1em;
    cursor: pointer;
}

.selectaccount-content .selectaccount-accounts .selectaccount-accounts-item:hover {
    background-color: #f1f1f1;

}

.selectaccount-content .selectaccount-accounts .selectaccount-accounts-item {
    outline: none !important;
}

.selectaccount-content .selectaccount-accounts .selectaccount-accounts-item *{
    outline: none !important;
}

.selectaccount-content .selectaccount-accounts-item .selectaccount-accounts-item-asset-container .selectaccount-accounts-item-asset-value {
    color: var(--primary-color);
    font-size: 1.1em;
}

.selectaccount-content .selectaccount-accounts-item .selectaccount-accounts-item-asset-container .selectaccount-accounts-item-asset-asof {
    opacity: 0.5;
    font-size: 0.8135em;
}