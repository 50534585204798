

/** BOX LAYOUT **/
@media all and (min-width: 480px) {
  .layout-content.box {
    margin: 60px 0 0;
  }

  .layout-content.box form {
    margin: 0 auto;
    max-width: 450px;
  }
}

.logo-container.box {
  text-align: center;
  margin-bottom: 1em;
}

.logo-container.box > img.logo-icon-image {
    height: 2.5em;
    margin-right: 0.25em;
    vertical-align: middle;
}

.logo-container.box > img.logo-text-image {
    vertical-align: middle;
    margin-top: 0.2em;
}

.content-container.box {
  background-color: #fff;
  border: 0px solid #d6d6d6;
  padding: 2em 2em 3em 2em;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 1em;
  -webkit-box-shadow: 0 4px 20px 0 rgba(18, 20, 24, 0.5);
  box-shadow: 0 4px 20px 0 rgba(18, 20, 24, 0.5);
  width: 85%;
}
.content-container.box .error-container {
  width: 85%;
  margin: 0.5em auto 0;
  font-size: 0.8em;
}
.sub-container.box {
  text-align: center;
  margin-top: 1em;
  cursor: default;
  font-weight: 300;
}

.sub-container.box * a {
  cursor: pointer;
}

/** FLOAT LAYOUT **/
@media all and (min-width: 480px) {
  .layout-content.float {
    margin: 60px 0 0;
  }

  .layout-content.float form {
    margin: 0 auto;
    max-width: 450px;
  }
}

.logo-container.float {
  text-align: center;
  margin-bottom: 1em;
}

.logo-container.float > img.logo-icon-image {
    height: 2.5em;
    margin-right: 0.25em;
    vertical-align: middle;
}

.logo-container.float > img.logo-text-image {
    vertical-align: middle;
    margin-top: 0.2em;
}

.content-container.float {
  background-color: #fff;
  padding: 5px 0 50px;
  max-width: 600px;
  margin: 0 auto;
}

.sub-container.float {
  text-align: center;
  margin-top: 1em;
  cursor: default;
  font-weight: 300;
}

.sub-container.float * a {
  cursor: pointer;
}

/** FILL LAYOUT **/
@media (min-width: 768px){
  .content-container.fill{
    padding: 2em 3em;
    margin: auto;
    min-height: 100%;
  }
}
@media (max-width: 768px){
  .content-container.fill{
    min-height: 100%;
  }
}

/** FAKEMODAL LAYOUT **/
@media all and (min-width: 480px) {
  .layout-content.fakemodal {
    padding: 60px 0;
  }
}

.logo-container.fakemodal {
  text-align: center;
  margin-bottom: 1em;
}

.content-container.fakemodal {
  background-color: #fff;
  max-width: 600px;
  margin: 0 auto;
}
.content-header.fakemodal{
  background-color: #4AB6AC;
  color: #FFFFFF;
  font-size: 1.5em;
  font-weight: 300;
  padding: 0.5em 1em;
}
.content-header.fakemodal ~ div {
  border: 1px solid #d0d1d2;
  border-top: none;
}

/** Sliding Pane Layout **/
.layout-container.sliding-pane-container{
  position: fixed;
  left: 0;
  top: 0;
  transform: translate3d(-50vw, 0, 0);

  transition: transform .5s cubic-bezier(0, .52, 0, 1);

  background-color: #fff;
  border-right: 2px solid var(--primary-color);

  width: 50vw;
  height: 100vh;
}
.layout-container.sliding-pane-container.active{
  transform: translate3d(0vw, 0, 0);
}
.layout-container.sliding-pane-container.right{
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(150vw, 0, 0);

  transition: transform .5s cubic-bezier(0, .52, 0, 1);

  background-color: #fff;
  border-left: 2px solid var(--primary-color);
  border-right: 0px solid var(--primary-color);

  width: 50vw;
  height: 100vh;
}
.layout-container.sliding-pane-container.right.active{
  transform: translate3d(50vw, 0, 0);
}

@media (max-width: 768px){
  .layout-container.sliding-pane-container{
    transform: translate3d(-100vw, 0, 0);
        
    width: 100vw;
  }
  .layout-container.sliding-pane-container.active{
    transform: translate3d(0vw, 0, 0);
  }
  .layout-container.sliding-pane-container.right{
    transform: translate3d(200vw, 0, 0);
        
    width: 100vw;
  }
  .layout-container.sliding-pane-container.right.active{
    transform: translate3d(0vw, 0, 0);
  }
}

.layout-cover.slidingpane.active{
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #d0d1d2;
  opacity: 0.5;
}

.layout-container.sliding-pane-container .content-container.slidingpane {
  max-height: 100vh;
  overflow: auto;
  padding-bottom: 1em;
}

.layout-container.sliding-pane-container .content-container.slidingpane .slidingpane-close{
  float: right;
  padding-right: 0.25em;
  font-size: 1.4em;
  cursor: pointer;
  opacity: 0.3;
}

.layout-container.sliding-pane-container.right .content-container.slidingpane .slidingpane-close{
  float: left;
  padding-right: 0.25em;
  font-size: 1.4em;
  cursor: pointer;
  opacity: 0.3;
}

.content-container.slidingpane .slidingpane-close:hover {
  opacity: 0.6;
}