.securitysettings-container * .securitysettings-grid {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 350px 1fr;
    grid-template-rows: auto 1fr;
    margin: 0 auto;
    border: none;
}

.securitysettings-container .securitysettings-security-container {
    background-color: #FFF;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 2em;
    width: 100%;
    max-width: 1000px;
    -webkit-box-shadow: 0px 1px 1px 0px #eaeaea;
    -moz-box-shadow: 0px 1px 1px 0px #eaeaea;
    box-shadow: 0px 1px 1px 0px #eaeaea;
    border-radius: 0.3em;
    display: grid;
    grid-template-columns: 100px auto
}
.securitysettings-container .securitysettings-security-container .securitysettings-info-image svg{
    width: 80%;
    height: 5em;
    color: #E2DEDD;
}

.securitysettings-container .securitysettings-security-container .securitysettings-info-name {
    font-size: 2em;
    font-weight: 300;
}
.securitysettings-container .securitysettings-security-container .securitysettings-info-email{
    font-size: 1.25em;
    color: var(--text-secondary);
}
.securitysettings-container .securitysettings-security-container .securitysettings-security-content {
    grid-column-start: 2;
    font-weight: 300;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-items .component-loading-placeholder > .component-loading-text {
    font-size: 1.25em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-security-item {
    margin: 0.5em 0;
}
.securitysettings-container .securitysettings-security-container .securitysettings-security-item span {
    cursor: pointer;
}
.securitysettings-container .securitysettings-security-container .securitysettings-security-item svg{
    color: #aaa;
    margin-right: 0.25em;
    font-size: 0.8em;
}

.securitysettings-container .securitysettings-security-container .securitysettings-security-item span:hover svg {
    color: var(--primary-color);
}

.securitysettings-container .securitysettings-security-container .securitysettings-devices-content {
    grid-column-start: 2;
    margin-top: 1em;
    font-weight: 300;
    max-width: 600px;
    position: relative;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-content .securitysettings-devices-header {
    color: var(--text-secondary);
    border-bottom: 1px solid #aaa;
    padding-right: 1em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-content .securitysettings-devices-item {
    padding: 0.5em 0;
    border-bottom: 1px solid #dfdfdf;
    display: grid;
    grid-template-columns: auto 2em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-content .securitysettings-devices-item:last-child {
    border-bottom: 1px solid transparent;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-row > div{
    display: inline-block;
    margin-bottom: 0.25em;
}
.securitysettings-devices-item-info-row {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info div.securitysettings-devices-item-info-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.2fr;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-location {
    font-size: 1.1em;
    font-weight: 300;
    padding-right: 1em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-time {
    font-size: 0.8em;
    color: var(--text-secondary);
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-os {
    padding-right: 1em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-os svg{
    color: var(--primary-color);
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-os span{
    font-size: 0.9em;
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-browser {
    padding-right: 1em;
    text-align: center;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-browser svg{
    color: var(--primary-color);
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-browser span{
    font-size: 0.9em;
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-type {
    padding-right: 1em;
    text-align: center;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-type svg{
    vertical-align: middle;
    color: var(--primary-color);
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info .securitysettings-devices-item-info-type span{
    font-size: 0.9em;
    text-transform: capitalize;
    vertical-align: middle;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-option-item {
    color: var(--text-secondary);
    text-align: center;
    padding-top: 0.1em;
    font-size: 0.8em;
}
.securitysettings-container .securitysettings-security-container .securitysettings-devices-item-option-item svg:hover{
    color: var(--danger-text);
    cursor: pointer;
}

#securitysettings-deactivate-popover .revoke-token-btn {
    border-radius: 0;
    max-width: 80%;
    margin: 0 auto;
}
/** Password Modal **/
.update-password-modal .modal-header{
    background-color: var(--primary-color);
}
.update-password-modal .modal-title {
    color: var(--header-text);
}
.update-password-modal .update-password-modal-content {
    width: 85%;
    margin: 0 auto;
}
.update-password-modal .update-password-modal-content .password-eye-icon {
    float: right;
    margin-right: 0.25em;
    margin-top: -1.5em;
    position: relative;
    z-index: 2;
    color: #9e9e9e;
    cursor: pointer;
  }
.update-password-modal .update-password-modal-content .update-password-mfa-text{
    font-size: 0.9em;
    color: var(--text-secondary);
    margin: 2em 0 0.5em;
}
.update-password-modal .update-password-modal-content .update-password-submit-btn {
    max-width: 225px;
    margin: 2.5em auto 0.5em;
}
/** SQ Modal **/
.update-sq-modal .modal-header{
    background-color: var(--primary-color);
}
.update-sq-modal .modal-title {
    color: var(--header-text);
}
.update-sq-modal .update-sq-modal-content {
    width: 85%;
    margin: 0 auto;
}
.update-sq-modal .update-sq-modal-content .control-label {
    font-size: 0.9em;
    color: var(--text-secondary);
}
.update-sq-modal .update-sq-modal-content .update-sq-mfa-text{
    font-size: 0.9em;
    color: var(--text-secondary);
    margin: 3em 0 1em;
}
.update-sq-modal .update-sq-modal-content .update-sq-question-text {
    margin-bottom: 0.75em;
    color: var(--primary-color)
}
.update-sq-modal .update-sq-modal-content .update-sq-submit-btn {
    max-width: 250px;
    margin: 2.5em auto 0.5em;
}

@media ( max-width: 915px ) { 
    .row {
        width: 100%;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-security-content, .securitysettings-container .securitysettings-security-container .securitysettings-devices-content {
        grid-row-start: unset;
        grid-column-start: unset;
    }

    .securitysettings-container * .securitysettings-grid, .securitysettings-container .securitysettings-security-container {
        grid-template-columns: 100%;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-info-name {
        font-size: 1.5em;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-info-email {
        font-size: 0.85em;
    }

    .securitysettings-container .securitysettings-security-container {
        padding-left: 1em;
        padding-right: 1em;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-info-image svg {
        margin-left: 10%;
    }

    .securitysettings-devices-item-info-row {
        font-size: 0.7em;
    }

    .securitysettings-container .securitysettings-security-container .securitysettings-devices-item-info div.securitysettings-devices-item-info-group {
        grid-template-columns: 33% 33% 34%;
    }
}