.password-criteria-row > div {
  display: inline-block;
}
.password-criteria-row > .password-criteria-row-check{
  width: 1.5em;
}
.password-criteria-row > .password-criteria-row-check .fa-check-circle {
  color: var(--primary-color);
  opacity: 1;
}

.password-criteria-row > .password-criteria-row-check .fa-circle {
  opacity: 0.5;
}

.password-criteria-row > .password-criteria-row-text{
  font-weight: 300;
  width: 94%;
}

@media ( max-width: 991px ) {
  .password-criteria-row {
    font-size: 0.75em
  }
}

#validSpecChars {
  font-weight: lighter;
  font-size: 0.75em;
  padding-left: 2%;
}