.resetpassword-container form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.resetpassword-container h1 {
  font-size: 1.25em;
  font-weight : 300;
  text-align: left;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
  cursor: default;
}

.resetpassword-container h2 {
  font-size: 1em;
  font-weight : 300;
  text-align: left;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
  cursor: default;
}

.resetpassword-container h3 {
  font-size: 1.25em;
  font-weight : 300;
  text-align: center;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
  cursor: default;
}

.resetpassword-container .reset-email-input{
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.resetpassword-container .reset-submit-btn {
  font-size: 1.125em;
  padding: 0.5em 0.875em;
}

.resetpassword-container .reset-check-img-container{
  max-width: 450px;
  text-align: center;
  margin: 2em auto 0.5em;
}

.resetpassword-container img.reset-check-image {
    width: 200px;
}
.resetpassword-container .password-eye-icon {
  float: right;
  margin-right: 0.25em;
  margin-top: -1.5em;
  position: relative;
  z-index: 2;
  color: #9e9e9e;
}
.resetpassword-container .password-criteria-container{
  margin-bottom: 1em;
}
.resetpassword-container .resetpassword-lang-select{
  text-align: center;
  margin-top: 1em;
  margin-bottom: -2em;
}

.resetpassword-container .sub-container.box {
  color: #fff;
}

.resetpassword-container .sub-container a {
  color: #fff;
  text-decoration: underline;
}

.resetpassword-container .sub-container a:hover {
  color: #babcc0;
}