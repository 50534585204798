.app-header .header-logo{
    margin-top: 0.5em;
    max-height: 35px;
    margin-bottom: 0.5em;
}

.app-header .navbar-lang > .dropdown-menu, .app-header .navbar-lang > .dropdown-toggle{
    min-width: 65px;
    border-radius: 0;
}

.app-header .navbar-lang > .dropdown-toggle{
    text-align: left;
}

.app-header .navbar-lang.open>a, .app-header .navbar-lang.open>a:focus, .app-header .navbar-lang.open>a:hover {
    background-color: var(--header-color-dark);
}

.app-header.navbar-inverse .navbar-collapse .open>a, .app-header.navbar-inverse .navbar-collapse .open>a:focus, .app-header.navbar-inverse .navbar-collapse .open>a:hover {
    text-decoration: underline;
    color: var(--header-text);
    background-color: var(--header-color);
}

.app-header .navbar-lang .dropdown-menu>li>a:focus, .app-header .navbar-lang .dropdown-menu>li>a:active, .app-header .navbar-lang .dropdown-menu>li>a:hover {
    color: #333;
}

.app-header.navbar-inverse .navbar-collapse, .app-header.navbar-inverse .navbar-form{
    max-width: 1600px;
    padding-right: 0.5em;
    margin: 0 auto;
    border-color: var(--header-color-dark);
}

.app-header .navbar-toggle{
    border: 0 solid transparent;
    margin-top: 1em;
    background-color: var(--header-color);
} 
.app-header .navbar-toggle .icon-bar {
    background-color: var(--header-text);
}
.app-header .navbar-toggle:hover, .app-header .navbar-toggle:active , .app-header .navbar-toggle:active {
    background-color: var(--header-color-dark);
}
.app-header .navbar-icon-sm-text{
    display: none;
}
.app-header .navbar-alerts a, .app-header .navbar-settings > a{
    font-size: 1.25em;
}
.app-header li.disabled {
    opacity: 0.5;
}

.app-header .dropdown-menu>li>a {
    font-weight: 6300;
}

@media (max-width: 767px) {
    .app-header.navbar-inverse .navbar-collapse, .app-header.navbar-inverse .navbar-form{
        margin-right: -15px;
        margin-left: -15px;
    }
    .app-header .navbar-lang > .dropdown-toggle{
        text-align: left;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: var(--header-text);
    }
    .app-header .navbar-lang .dropdown-menu>li>a {
        color: var(--header-text);
    }
    .app-header .header-logo{
        margin-left: 0.5em;
    }
    .app-header .navbar-icon-sm-text{
        display: inline-block;
    }
    .app-header .navbar-icon{
        display: none;
    }
    .app-header .navbar-alerts a, .app-header .navbar-settings > a{
        font-size: 1em;
    }
    .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
        max-height: 100%;
    }
}
@media ( max-width: 991px ) and ( min-width: 767px ) {
    .navbar-inverse .system-box {
        font-size: 0.8em;
        padding-top: 0.8em;
    }
}
@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus, .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
        text-decoration: underline;
        color: var(--header-text);
    }
}
/** Navbar Default override **/
.navbar-inverse{
    background-color: #3c434d;
    border-color: #3c434d;
    color: var(--header-text);
}
.navbar-inverse .navbar-brand {
    color: var(--header-text);
}
.navbar-inverse .navbar-brand > img {
    padding: 0 0;
    max-height: 40px;
}
.navbar-inverse .navbar-nav>li>a {
    color: var(--header-text);
    font-size: 1.125em;
    font-weight: 400;
}
.navbar-inverse .navbar-nav .nav-logout-btn > a {
    border: 1px solid var(--header-color);
    margin: 0.5em;
}
.navbar-inverse .navbar-nav>li>a:focus, .navbar-inverse .navbar-nav>li>a:hover {
    color: var(--header-text);
}
@media (min-width: 768px){
    .navbar-inverse .navbar-nav .nav-logout-btn > a {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-right: 5px;
    }
    .navbar-spacer-left{
      margin-left: 3em;
    }
    .app-header .navbar-nav>li.navbar-alerts>a, .app-header .navbar-nav>li.navbar-settings > a{
        font-size: 1.375em;
        margin: 0.775em 0.5em 0.375em;
    }
    .app-header .navbar-nav>li.navbar-auth-text>a {
        font-size: 1em;
        margin-top: 1.1em; 
    }
    .app-header .system-box {
        font-size: 0.85em;
        padding-top: 0.45em;
    }
}
  .navbar-nav>li>a {
      padding: 0 0 0.1875em;
      margin: 1em 0.9375em 0.375em;
  }
  .navbar-inverse .navbar-nav>li>a:hover {
    color: var(--primary-color-dark);
  }
  .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
    color: var(--header-text);
    background-color: var(--header-color);
  }

  .navbar-inverse .navbar-nav>.active>a {
    border-bottom: 1px solid var(--text-underline);
    color: var(--header-text);
    background-color: var(--header-color);
  }
  .app-header.navbar-inverse .navbar-lang.dropdown{
      max-width: 2.5em;
  }
  .app-header.navbar-inverse .navbar-lang.dropdown>a{
      margin-left: 0.675em;
  }
  @media (max-width: 767px) {
    .app-header.navbar-inverse .navbar-collapse.in  {
        height: calc(100% - 90px);
        overflow-y: scroll;
    }
    .navbar-nav .open .dropdown-menu{ 
        margin-left: 2em;
        border-left: 1px solid var(--header-color-dark);
    }
    .navbar-nav .open .dropdown-menu .dropdown-toggle{
        padding-left: 1em;
    }
    .navbar-nav .open .dropdown-submenu .dropdown-menu, .navbar-nav .open .dropdown-submenu-left .dropdown-menu { 
      margin-left: 2em;
      border-left: 1px solid var(--header-color-dark);
    }
    .navbar-nav .open .dropdown-submenu .dropdown-menu a, .navbar-nav .open .dropdown-submenu-left .dropdown-menu a {
      padding-left: 1em;
    }
    .navbar-nav>li>a {
        display: inline-block;
    }
    .app-header.navbar-inverse .navbar-lang.dropdown>a{
        padding: 0 0 0.1875em;
        margin: 1em 0.9375em 0.375em;
    }
  }